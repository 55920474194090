export const i18next_en = {
    navigation: {
      map: 'Map',
      stats: 'Stats',
      dataset: 'Dataset',
      home_link: "/",
      map_link: "/map",
      stats_link: "/stats",
      reports_link: "/reports",
      home_upper: 'HOME',
      map_upper: 'MAP',
      stats_upper: 'STATS',
      dataset_upper: 'DATASET'
    },
    map: {
      last_7_days_tag: 'Last 7 days',
      last_30_days_tag: 'Last 30 days',
      range_tag: 'Range',
      start_date_tag: 'Start date',
      end_date_tag: 'End date',
      apply_tag: 'Apply',
      filter_by_disease: 'Filter by disease',
      filter_by_syndrome: 'Filter by syndrome',
      filter_by_tag: 'Filter by disease/syndrome',
      legend_tag: 'Legend',
      diseases_tag: 'Disease(s)',
      syndromes_tag: 'Syndrome(s)',
      report_tag: 'report',
      reports_tag: 'reports',
      map_background: 'Map Background',
      street_map_bg: 'Street Map',
      topo_map_bg: 'Topo Map',
      imagery_map_bg: 'Imagery',
      light_map_bg: 'Light',
      dark_map_bg: 'Dark',
      chart_colours: 'Chart Colours',
      bright_chart: 'Bright',
      general_search: 'General search',
      diseases_selected: '{{val, number}} disease(s) selected',
      syndromes_selected: '{{val, numer}} syndrome(s) selected',
      all_diseases: 'All diseases',
      all_diseases_upper: 'ALL DISEASES',
      clear: 'Clear',
      loading: 'Loading... please wait'
    },
    stats: {
      country_tag: 'Country',
      start_date_tag: 'Start Date',
      end_date_tag: 'End Date',
      diseases_tag: 'Disease(s)',
      syndromes_tag: 'Syndrome(s)',
      disease_top_10_tag: 'Auto Disease Top 10',
      apply_tag: 'Apply',
      legend_tag: 'Legend',
      bar_chart_date_tag: 'Date',
      bar_chart_reports_tag: 'Reports',
      country_distribution_tag: 'Country Distribution',
      regional_distribution_tag: 'Regional Distribution',
      distribution_within_tag: 'Distribution within {{val, string}}',
      reports_for_tag: 'Reports for {{val, string}}',
      disease_top_10_hover: 'If ON, will automatically select the top 10 diseases for the country and the time range selected. Otherwise, the selected diseases will remain unchanged.',
      initial_stacked_bar_chart_title: "Reports for Australia",
      initial_country_chart_title: "Distribution within Australia",
      initial_region_chart_title: "Distribution within Australia and New Zealand",
      diseases_selected: '{{val, number}} disease(s) selected',
      syndromes_selected: '{{val, numer}} syndrome(s) selected'
    },
    dataset: {
      date_tag: 'Date',
      diseases_tag: 'Diseases',
      syndromes_tag: 'Syndromes',
      country_tag: 'Country',
      location_tag: 'Location'
    }
  };