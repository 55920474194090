<template>
    <div>
        <app-navigation/>
        <div :class="mobile ? 'header-section-mobile': 'header-section'">
          <v-carousel v-model="model" cycle interval="10000" style="width: 100%; height: 100%;" hide-delimiter-background>
            <v-carousel-item
              v-for="(item, i) in header_carousel"
              :key="item"
              :src="item"
              style="height: 100% !important; width: 100% !important;"
            >
              <div v-if="i==0" :class="mobile ? 'header-text-mobile' : 'header-text'">
                <div :class="mobile ? 'header-title-mobile' : 'header-title'">Prevent the Next Pandemic with Epidemic Intelligence</div>
                <div :class="mobile ? 'section-paragraph-mobile header-description-mobile' : 'section-paragraph header-description'">Harness the power of AI and open-source data to capture early epidemic signals globally and rapid epidemic detection, leading to the prevention of global spread.</div>
              </div>
              <div v-if="i==1" :class="mobile ? 'header-text-mobile' : 'header-text'" style="width: 100%; margin: 0;">
                <v-img width="30%" max-width="1000px" style="margin: 0 auto; margin-bottom: 80px;" src="/home/IABCA-logo.png"/>
              </div>
              <div v-if="i==2" :class="mobile ? 'header-text-mobile' : 'header-text'" style="width: 50%; right: 40vw;">
                <v-img width="80%" max-width="1000px" style="margin-bottom: 40px;" src="/home/award-3.png"/>
              </div>
              <div v-if="i==3" :class="mobile ? 'header-text-mobile' : 'header-text'">
                <div :class="mobile ? 'header-title-mobile' : 'header-title'">EPISCOPE: The Weekly Digest</div>
                <div :class="mobile ? 'section-paragraph-mobile header-description-mobile' : 'section-paragraph header-description'">Our weekly digest providing a global view of early epidemic signals and outgoing outbreak information.</div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
        <imagine-section/>
        <vision-section/>
        <home-map/>
        <div :class="mobile ? 'blue-section-mobile' : 'blue-section'" style="text-align: center; margin: auto 0;">
          <div :class="mobile ? 'section-title-mobile' : 'section-title'">Balvi funds Shiba Inu OSINT initiative </div>
          <v-btn href="https://www.9news.com.au/national/ethereum-founder-gives-5-million-to-aussie-uni-for-pandemic-technology/3cf6b227-0250-4bc6-bc7b-8d8199f3b0d5" target="_blank" x-large class="red-button">Learn more</v-btn>
        </div>
        <div :class="mobile ? 'white-section-mobile' : 'white-section'" style="text-align: center; margin: auto 0;">
          <div :class="mobile ? 'section-title-mobile' : 'section-title'">Want to make an enquiry or provide feedback?</div>
          <v-btn x-large class="red-button" :href="'/media/Brochure.pdf'" target="_blank" style="margin: 5px;">Read our brochure</v-btn>
          <v-btn to="/contact" x-large class="red-button" style="margin: 5px;">Contact us</v-btn>
          <v-btn href="https://forms.gle/BxynzGoRSasaBrRJ7" target="_blank" x-large class="red-button" style="margin: 5px;">Provide feedback</v-btn>
        </div>
        <app-footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'
import ImagineSection from '@/components/home/Imagine.vue'
import VisionSection from '@/components/home/HomeVision.vue'
import HomeMap from '@/components/home/HomeMap.vue'

export default {
  name: 'Home',
  components: {
      AppNavigation,
      AppFooter,
      // AppBanner,
      ImagineSection,
      VisionSection,
      HomeMap
  },
  data: () => ({
      windowWidth: null,
      mobile: null,
      activeService: 0,
      interactiveMap: false,
      model: 0,
      header_carousel: [
        '/home/home-header.jpeg',
        '/home/IABCA-carousel.png',
        '/home/peoples_choice_edit_3.png',
        '/home/episcope_carousel_2.png'
      ],
      serviceItems: [
        { icon: "/home/services/epidemic-globe.svg", iconActive: "/home/services-active/epidemic-globe.svg", label: "Early epidemic detection, globally", description: "EPIWATCH is an artificial intelligence-driven system harnessing vast, open-source data to generate automated early warnings for epidemics worldwide. It has full language and geographic information system capability, using our leading expertise in field epidemiology and epidemic response. EPIWATCH is proven to identify outbreak signals earlier than traditional laboratory or hospital-based surveillance and can provide a trigger to investigate an early outbreak signal." },
        { icon: "/home/services/magnify-epidemic.svg", iconActive: "/home/services-active/magnify-epidemic.svg", label: "Modelling and simulation", description: "We provide a range of customised tools including:\n • Prioritise response to outbreaks (red, amber, green light)\n • Geographical Information Systems for global hot-spot analysis\n • Forecasting and prediction of epidemic trajectory\n • Choosing between alternative epidemic control strategies\n • Simulations customized to your needs, including internal and external spaces\n • Surge capacity predictions for hospitals and health systems " },
        { icon: "/home/services/shield.svg", iconActive: "/home/services-active/shield.svg", label: "Real-time decision support", description: "EPIWATCH monitors global epidemics intelligence and provides real-time epidemic signals in outbreak alerts. The risk analysis tools within EPIWATCH system provides rapid analysis of epidemics for use by the first responders, governments, policy makers, and other stakeholders. We provide flexible access to EPIWATCH on the web, on smartphone Apps and in a live decision theatre for government and non-government stakeholders to understand potential threats and optimal intervention methods through informed, interactive decision making."},
        { icon: "/home/services/desktop-virus.svg", iconActive: "/home/services-active/desktop-virus.svg",label: "Rapid risk analysis tools", description: "EPIWATCH has seamless integration of risk analysis tools including Flucast and EpiRisk designed for real-time risk prediction of identified disease events having a serious outcome, to prioritise response and urgency of intervention. Tailored user interfaces on the web and mobile applications are developed for real-time decision support.\n\nWe have a substantial track record in published research on differentiating natural and unnatural epidemics, including use of risk analysis tools to do so. This includes the Modified Grunow Finke Criteria, using a published method we developed to improve the sensitivity and specificity of the most commonly used tool for determining origins, the Grunow Finke Criteria. We can perform an analysis for any given outbreak." },
        { icon: "/home/services/epidemic-phone.svg", iconActive: "/home/services-active/epidemic-phone.svg", label: "Rapid epidemic reports", description: "Watching Briefs are intended as a resource for public health and other first responders in the field, who may not have the time to review past outbreaks or disease details. These reports are a critical analysis on global infectious disease outbreaks of interest using field data or open source data. Watching Briefs are reviewed by trained field epidemiologists who will work with authors to develop the briefs. Please contact us for any requests for Watching Briefs, resources and other information." },
        { icon: "/home/services/big-virus.svg", iconActive: "/home/services-active/big-virus.svg", label: "Customizable alerts", description: "EPIWATCH team has capability to customize alerts for epidemic-related and non-epidemic related events. The customised alerts can be added to EPIWATCH system, using artificial intelligence technology and open source data to generate automated information." },
      ]
    }),
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener('resize', this.checkScreen)
  },
  mounted() {
    this.checkScreen();
  },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      },
      selectService(id) {
        this.activeService = id;
        return;
      },
      toggleMapInteractive(flag) {
        this.interactiveMap = flag;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

iframe {
  overflow: hidden;
  pointer-events: auto;
}

.v-carousel__item {
  height: 100% !important;
}
</style>