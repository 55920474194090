<template>
    <!-- <v-container class="fill-height py-0 my-0 px-0 mx-0">
        <v-row style="height:100%" class="py-0 my-0 px-0 mx-0">
            <v-col style="height: 100%" class="py-0 my-0 px-0 mx-0"> -->
                <!-- <v-btn small @click="clear_filters">clear filters</v-btn> -->
                <div style="height:100%">
                <ag-grid-vue style="width:100%"
                    class="ag-theme-alpine fill-height"
                    id="reportGrid"
                    @grid-ready="gridIsReady"
                    :gridOptions="gridOptions"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    :rowData="rowData"
                    :suppressMultiSort="true"
                    :localeText="this.localeText"
                    >
                </ag-grid-vue>
                </div>
            <!-- </v-col>
        </v-row>
    </v-container> -->
</template>


<script>
    import { AgGridVue } from "ag-grid-vue";
    import axios from 'axios'
    import config from '../../config'
    import { AG_GRID_LOCALE_EN } from "../../locales/en/ag-grid.locale.en";
    import { AG_GRID_LOCALE_HI } from "../../locales/hi/ag-grid.locale.hi";
    import i18next from 'i18next';

    export default {
        name: 'App',
        data() {
            return {
                gridOptions: null,
                rowModelType: 'infinite',
                cacheBlockSize: 50,
                cacheOverflowSize: 2,
                rowSelection: null,
                rowBuffer: 0,
                gridApi: null,
                maxConcurrentDatasourceRequests: 1,
                infiniteInitialRowCount: null,
                maxBlocksInCache: 10,
                columnDefs: null,
                defaultColDef: null,
                loading: false,
                width: '100%',
                height: '100%',
                rowData: null,
                headerOptions: {
                    headers: {
                        "Accept-Language": this.$i18next.language
                    }
                }
            }
        },
        components: {
            AgGridVue
        },
        methods: {
            gridIsReady() {
            },
            loadData() {
                axios.post(config.api_url + "api/grid", {}, this.headerOptions).then((response) => {
                    this.rowData = response.data.results
                }).catch(() => {
                    // console.log(e)
                }).finally(() => {

                })
            }
        },
        beforeMount() {
            let lang = ['en', 'hi'].includes(this.$route.query.lang) ? this.$route.query.lang : 'en';
            if (this.$i18next.language !== lang) {
                i18next.changeLanguage(lang);
            }
            if (lang == 'hi') {
                this.localeText = AG_GRID_LOCALE_HI;
            } else {
                this.localeText = AG_GRID_LOCALE_EN;
            }
            this.headerOptions = {
                headers: {
                    "Accept-Language": lang
                }
            };
            this.gridOptions = {}
            this.gridOptions.components = {
                colWithLoader: (params) => {
                    if (params.value !== undefined) {
                        return params.value;
                    } else {
                        return '<img src="/loader.gif">';
                    }
                },
                titleLinkCell: (params) => {
                    if (params.value) {
                        return '<a target="_blank" href="' + encodeURI(params.data.url) + '">' + params.value + '</a>'
                    }
                    return ""
                }
            }

            this.infiniteInitialRowCount = this.cacheBlockSize + 2
            this.defaultColDef = {
                    resizable: true,
                    minWidth: 150,
                    sortable: true,
                    floatingFilter: true,
                    filter: "agTextColumnFilter",
                    filterParams: { buttons: ['apply','reset'], closeOnApply: true, filterOptions: ['contains','equals'], suppressAndOrCondition: true, defaultOption: "contains" }
            }
            
            this.columnDefs = [
                // { field: 'id', cellRenderer: 'colWithLoader', filter: false },
                { field: 'date', width: "210", cellRenderer: 'colWithLoader', headerName: this.$t("dataset.date_tag"), filter: 'agDateColumnFilter',
                    filterParams: {
                        buttons: ['apply','reset'],
                        closeOnApply: true,
                        filterOptions: ['equals','lessThan','greaterThan'],
                        suppressAndOrCondition: true,
                        defaultOption: "greaterThan",
                        comparator: (filterLocalDateAtMidnight, cellValue) => {
                            const dateAsString = cellValue;

                            if (dateAsString == null) {
                                return 0;
                            }

                            // In the example application, dates are stored as dd/mm/yyyy
                            // We create a Date object for comparison against the filter date
                            const dateParts = dateAsString.split('-');
                            const year = Number(dateParts[0]);
                            const month = Number(dateParts[1]) - 1;
                            const day = Number(dateParts[2]);
                            const cellDate = new Date(year, month, day);

                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            }
                            return 0;
                        }
                    },
                    sort: "desc"
                },
                { field: 'diseases', headerName: this.$t("dataset.diseases_tag"), flex:1 },
                { field: 'syndromes', headerName: this.$t("dataset.syndromes_tag"), flex:1 },
                // { field: 'title',flex:2,cellRenderer: 'titleLinkCell' },
                { field: 'country_name',flex:1, headerName: this.$t("dataset.country_tag")  },
                // { field: 'location', headerName: this.$t("dataset.location_tag"), flex:1 }
            ]
            this.loadData();

        },
        mounted() {
            
            let lang = ['en', 'hi'].includes(this.$route.query.lang) ? this.$route.query.lang : 'en';
            if (this.$i18next.language !== lang) {
                i18next.changeLanguage(lang);
            }
            if (lang == 'hi') {
                this.localeText = AG_GRID_LOCALE_HI;
            } else {
                this.localeText = AG_GRID_LOCALE_EN;
            }

            this.headerOptions = {
                headers: {
                    "Accept-Language": lang
                }
            };
            

        }
    }
</script>