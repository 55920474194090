<template>
    <div>
        <app-navigation/>
        <div :class="mobile ? 'white-section-mobile' : 'white-section'" :style="mobile ? 'padding: 50px 20px; margin-top: 30px;' : 'padding: 70px 50px; margin-top: 60px;'" >
          <div style="width: 100%; max-width: 1000px; margin: 0 auto;">
          <div :class="mobile ? 'section-title-mobile' : 'section-title'">Make an enquiry</div>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="firstName" :rules="nameRules" label="First name *" background-color="white" filled dense outlined required></v-text-field>
            <v-text-field v-model="lastName" :rules="nameRules" label="Last name *" background-color="white" filled dense outlined required></v-text-field>
            <v-text-field v-model="companyName" :rules="nameRules" label="Company name *" background-color="white" filled dense outlined required></v-text-field>
            <v-text-field v-model="email" :rules="emailRules" label="Email *" background-color="white" filled dense outlined required></v-text-field>
            <v-textarea v-model="message" :rules="messageRules" label="Message *" counter="120" background-color="white" filled dense outlined required></v-textarea>
            <br/>
            <div style="margin-right: 20px;">
            <vue-recaptcha
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            @error="onCaptchaExpired"
            :sitekey="recaptchaSiteKey"
            >
            </vue-recaptcha>
            </div>
            <br/>
            <span>
            <v-btn x-large class="red-button" :disabled="!submitVerified" @click="submit" style="margin-right: 10px; float: left;">
                Submit
            </v-btn>
            <v-alert v-if="emailed" color="#d93b38" style="float: left;" dismissible dense dark height="36px">
              Your email has been received. We will reply back to you soon.
            </v-alert>
            </span>
          </v-form>
          </div>
        </div>
        <app-footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue';
import VueRecaptcha from 'vue-recaptcha'

import axios from 'axios'
import config from '../config'

export default {
  name: 'Contact',
  components: {
      AppNavigation,
      AppFooter,
      VueRecaptcha
  },
  data: () => ({
      mobile: null,
      recaptchaSiteKey: config.recaptchaSiteKey,
      windowWidth: null,
      emailed: false,
      submitVerified: false,
      recaptchaToken: null,
      valid: false,
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      message: "",
      nameRules: [
        v => (!!v && v.length > 0) || 'Field is required'
      ],
      emailRules: [
        v => !!v || 'Field is required',
        v => (v && v.length > 0 && /.+@.+/.test(v)) || 'Field must be a valid email'
      ],
      messageRules: [
        v => !!v || 'Field is required',
        v => (v && v.length > 0 && v.length <= 120 || 'Maximum limit of 120 characters for message')
      ]
    }),
    watch: {
      valid: function (val) {
        this.submitVerified = this.recaptchaToken && val && !this.emailed
      },
      recaptchaToken: function (val) {
        this.submitVerified = val && this.valid && !this.emailed
      }
    },
  created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    mounted() {
      this.checkScreen();
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      
        return;
      },
      selectService(id) {
        this.activeService = id;
        return;
      },
      submit() {
        const self = this;
        if (self.submitVerified && self.recaptchaToken) {
          self.submitVerified = false
          let bodyObjectToSend = {
              recaptchaToken: self.recaptchaToken,
              email: self.email,
              firstName: self.firstName,
              lastName: self.lastName,
              companyName: self.companyName,
              message: self.message
          }

          axios.post(config.api_url + "api/email", bodyObjectToSend).then(() => {
              self.emailed = true
              self.recaptchaToken = null
          })
        }
      },
      onCaptchaVerified(recaptchaToken) {
          this.recaptchaToken = recaptchaToken
      },
      onCaptchaExpired() {
          this.$refs.recaptcha.reset()
          this.recaptchaToken = null
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

a {
  text-decoration: none;
}

.text-field-dense-label-top {
  font-size: 1.125em !important;
}
</style>