<template>
    <div>
        <div v-if="mobile">
      <div
        class="white-section-mobile"
      >
        <div class="section-title-mobile" >Services</div>
        <div class="section-paragraph-mobile">
          We provide a range of customised alerts and system
modelling to suit your needs including:
        </div>
        <br/>
        <ul class="section-paragraph-mobile">
<li>Triggers to investigate early outbreak signals</li>
<li>Detecting the origins of epidemics</li>
<li>Response prioritisation to outbreaks with a severity index</li>
<li>Geographical Information Systems for global hot-spot analysis</li>
<li>Forecasting and prediction of epidemic trajectory</li>
<li>Choosing between alternative epidemic control strategies</li>
<li>Simulations customised to your needs, including internal and external spaces</li>
<li>Surge capacity predictions for hospitals and health systems</li>
<li>Non-medical event detection for response-readiness and resource planning</li>
</ul>
<br/>
<div class="section-paragraph-mobile" style="font-weight: bold;">For customised services to suit your requirements:</div>
<br/>
<v-btn to="/contact" x-large class="red-button section-paragraph-mobile">Contact us</v-btn>
        </div>
        <div
        style="
          height: 25vh;
          background-color: gray;
          position: relative;
          background-image: linear-gradient(0deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/products/services-2.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        "
      >
      </div>
        </div>
        <div v-if="!mobile" style="width: 100%; display: flex; background-color: #e6e8e8; padding-left: 50px;">
      <div style="width: 100%; max-width: 1500px; margin: 0 auto; display: flex;">
      <div
        id="description"
        class="white-section"
        style="flex: 2; position: relative; margin: 0 -50px;"
      >
      <div id="inner-services" style="min-width: 500px;">
        <div class="section-title" >Services</div>
        <div class="section-paragraph">
          We provide a range of customised alerts and system
modelling to suit your needs including:
        </div>
        <br/>
        <ul class="section-paragraph">
<li>Triggers to investigate early outbreak signals</li>
<li>Detecting the origins of epidemics</li>
<li>Response prioritisation to outbreaks with a severity index</li>
<li>Geographical Information Systems for global hot-spot analysis</li>
<li>Forecasting and prediction of epidemic trajectory</li>
<li>Choosing between alternative epidemic control strategies</li>
<li>Simulations customised to your needs, including internal and external spaces</li>
<li>Surge capacity predictions for hospitals and health systems</li>
<li>Non-medical event detection for response-readiness and resource planning</li>
</ul>
<br/>
<div class="section-paragraph" style="font-weight: bold;">For customised services to suit your requirements:</div>
<br/>
<v-btn to="/contact" x-large class="red-button">Contact us</v-btn>
      </div>
      </div>
      <div
      id="services-right"
        style="
          flex: 3;
          background-color: gray;
          position: relative;
          background-image: linear-gradient(90deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/products/services-2.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: inherit;
        "
      >
        <svg
          id="services-wave"
          xmlns="http://www.w3.org/2000/svg"
          style="float: left"
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 12 843 800"
        >
          <path
            fill="#e6e8e8"
            fill-opacity="1"
            d="M 489.537 1373.669 L 478.361 1340.811 C 466.792 1308.022 445.066 1242.397 395.957 1180.992 C 347.364 1120.207 272.406 1063.664 260.598 996.697 C 248.617 928.745 299.663 849.68 298.392 780.855 C 296.606 711.41 243.384 651.441 199.593 589.098 C 156.497 527.242 122.919 463.504 121.77 393.643 C 120.777 324.667 150.175 249.521 133.443 183.422 C 116.554 116.437 51.549 58.748 19.571 28.847 L -12.627 0 L -12.627 0 L -6.671 33.779 C 0 67.558 0 134.919 0 202.871 C 0 270.133 0 337.789 0 404.756 C 0 472.708 0 540.66 0 607.626 C 0 675.283 0 742.545 0 801.899 C 0 877.858 0 803.899 0 997.899 C 0 1160.899 0 997.899 0 1148.899 C 0 1340.899 0 1148.899 0 1307.899 L 0 1373.669 Z"
          ></path>
        </svg>
      </div>
      </div>
    </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Services',
  components: {
  },
  data: () => ({
      mobile: null,
      windowWidth: null
    }),
  created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen()
    },
    mounted() {
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }

        if (document.getElementById("inner-services")) {
          let innerHeight = document.getElementById("inner-services").clientHeight;
          if (document.getElementById("services-wave")) {
            document.getElementById("services-wave").setAttribute("style", "float: left; height: 100%; height: calc("+innerHeight+ "px + 100px);")
            document.getElementById("services-wave").style.height = "calc(" + innerHeight + "px + 100px)"
          }
          if (document.getElementById("services-right")) {
            document.getElementById("services-right").setAttribute("style", "flex: 3; max-height: calc("+innerHeight+"px + 100px);background-color: gray; position: relative; background-size: cover; background-repeat: no-repeat; background-position: center; height: inherit; background-image: linear-gradient(90deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/products/services-2.jpg');")
            document.getElementById("services-right").style.maxHeight = "calc(" + innerHeight + "px + 100px)"
          }
        }
        return;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>