<template>
    <div>
        <div v-if="mobile" style="width: 100%;">
          <div style="height: 50vh; background-image: url('/home/home-map.png'); background-size: cover; background-position: right center;">
            <v-card hover style="position: absolute; margin: 20px; background-color: rgba(255, 255, 255, 0.8); padding: 20px; 100%; color: #051238;">
              <div class="section-title-mobile">Dashboard</div>
              <div class="section-paragraph-mobile">Interact with our public dashboard and discover emerging outbreaks in real-time.</div>
              <br/>
              <v-btn to="/map" x-large class="red-button">Check it out now</v-btn>
            </v-card>
          </div>
        </div>
        <div v-if="!mobile" style="overflow: hidden; width: 100%">
          <!-- <div style="width: 100%; max-width: 1500px; margin: 0 auto; height: 100%;"> -->
                      <div style="height: 50vh; background-image: url('/home/home-map.png'); background-size: cover; background-position: right center;">

            <v-card hover style="position: absolute; margin-left: 50px; margin-top: 50px; background-color: rgba(255, 255, 255, 0.8); padding: 50px; width: 300px; color: #051238;">
              <div class="section-title">Dashboard</div>
              <div class="section-paragraph">Interact with our public dashboard and discover emerging outbreaks in real-time.</div>
              <br/>
              <v-btn to="/map" x-large class="red-button">Check it out now</v-btn>
            </v-card>
          </div>
          <!-- <iframe id="interactive-map" src="/map" scrolling="no" scroll="no" style="border: 0px none; margin-left: -260px; height: 800px; margin-top: -290px; width: 200%; margin-bottom: -8px; margin-right: -400px; background-color: black;"></iframe> -->

        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeMap',
  components: {
  },
  data: () => ({
      windowWidth: null,
      mobile: null,
      interactiveMap: false,
    }),
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener('resize', this.checkScreen)
  },
  mounted() {
    this.checkScreen();
  },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      },
      toggleMapInteractive(flag) {
        this.interactiveMap = flag;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

iframe {
  overflow: hidden;
  pointer-events: auto;
}
</style>