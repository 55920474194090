<template>
    <div>
        <app-navigation/>
        <app-banner
          backgroundUrl="/publications/publications-header-1.png"
          :headerButtons="[]"
          backgroundSize="cover"
          title="Curated data at your fingertips"
          description="A wide collection of data curated for your needs."
        />
        <div style="text-align: center; background-color: #e6e8e8;">
          <div :class="mobile ? 'white-section-mobile' : 'white-section'" style="width: 100%; max-width: 1500px; margin: 0 auto; height: 100%;">
            <v-tabs v-model="active" centered>
              <v-tab v-for="tab in tabs" :key="tab.key">
                {{ tab.title }}
              </v-tab>
              <v-tab-item v-for="tab in tabs" :key="tab.key" :style="mobile ? 'padding-bottom: 10px;' : 'padding-bottom: 30px;'">
                <a :v-if="!loading" v-for="item in publications[tab.key]" :key="item.link"  :href="item.link" target="_blank" style="width: calc(100% + 50px); margin-left: -50px; text-align: left;">
                <v-card hover style="background-color: white; text-transform: none !important; font-weight: none !important;" :style="mobile ? 'padding: 20px; margin: 0px 20px;' : 'padding: 50px; margin: 0px 50px;'">
                  <div :class="mobile ? 'section-title-mobile' : 'section-title'" style="width: 100%; text-decoration: underline; color: #213d8c; ">{{item.title}}</div>
                  <div :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph'" style="width: 100%;">{{item.authors}}</div>
                </v-card>
                </a>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
        <app-footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppBanner from '@/components/AppBanner.vue'
import axios from 'axios';

export default {
  name: 'Publications',
  components: {
      AppNavigation,
      AppFooter,
      AppBanner
  },
  data: () => ({
      mobile: null,
      windowWidth: null,
      publications: null,
      tabs: [
        {key: 'peer_reviewed', title: 'Peer Reviewed'},
        {key: 'global_biosecurity', title: 'Global Biosecurity'},
        {key: 'watching_briefs', title: 'Watching Briefs'}
      ],
      loading: true
    }),
  created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    async mounted() {
      this.checkScreen();
      this.fetch_publications().then((data) => {
        this.publications = data
        this.loading = false
      })
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
        return;
      },
      fetch_publications() {
        return new Promise((resolve, reject) => {
          axios.get("data/publications.json").then((response) => {
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {})
        })
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

a {
  text-decoration: none;
}
</style>