<template>
    <div>
        <app-navigation/>
        <app-banner
          backgroundUrl="/products/products-header.jpeg"
          :headerButtons="headerButtons"
          backgroundSize="cover"
          title="The ‘Global Eye’ monitors a wide variety of data 24/7"
          description="The EPIWATCH system is driven by artificial intelligence and machine learning to identify outbreaks at its genesis and for rapid risk analysis."
        />
        <services-section/>
        <products-section/>
        <app-footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppBanner from '@/components/AppBanner.vue'
import ServicesSection from '@/components/products/Services.vue'
import ProductsSection from '@/components/products/ProductsSection.vue'

export default {
  name: 'Products',
  components: {
      AppNavigation,
      AppFooter,
      AppBanner,
      ServicesSection,
      ProductsSection
  },
  data: () => ({
      mobile: null,
      windowWidth: null,
      activeService: 0,
      headerButtons: ['Outbreak Alerts', 'EPISCOPE', 'Watching Briefs', 'EPIRISK', 'FLUCAST', 'ORIGINS', 'Biothreat Training']
    }),
  created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    mounted() {
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }

        for (let i = 0; i < this.products.length; i++) {
          if (document.getElementById("inner-description" + i)) {
            let innerSectionHeight = document.getElementById("inner-description" + i).clientHeight;
            if (document.getElementById("image-right" + i)) {
              document.getElementById("image-right" + i).setAttribute("style", "flex: 3; max-height: calc("+innerSectionHeight+"px + 100px);background-color: gray; position: relative; background-size: cover; background-repeat: no-repeat; background-position: center; height: inherit; background-image: url('"+this.products[i].img+"')")
              document.getElementById("image-right" + i).style.maxHeight = "calc(" + innerSectionHeight + "px + 100px)"
            }
            if (document.getElementById("image-wave-" + i)) {
              document.getElementById("image-wave-" + i).setAttribute("style", "float: left; height: calc("+innerSectionHeight+ "px + 100px);")
              document.getElementById("image-wave-" + i).style.height = "calc(" + innerSectionHeight + "px + 100px)"
            }
          }
        }
        if (document.getElementById("inner-services")) {
          let innerHeight = document.getElementById("inner-services").clientHeight;
          if (document.getElementById("services-wave")) {
            document.getElementById("services-wave").setAttribute("style", "float: left; height: 100%; height: calc("+innerHeight+ "px + 100px);")
            document.getElementById("services-wave").style.height = "calc(" + innerHeight + "px + 100px)"
          }
          if (document.getElementById("services-right")) {
            document.getElementById("services-right").setAttribute("style", "flex: 3; max-height: calc("+innerHeight+"px + 100px);background-color: gray; position: relative; background-size: cover; background-repeat: no-repeat; background-position: center; height: inherit; background-image: linear-gradient(90deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/products/services-2.jpg');")
            document.getElementById("services-right").style.maxHeight = "calc(" + innerHeight + "px + 100px)"
          }
        }
        return;
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>