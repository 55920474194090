<template>
    <v-container class="fill-height pa-0" max fluid>
        <v-row style="height:100%" class="py-0 my-0 px-0 mx-0">
            <v-col style="height: 100%" class="pa-0">
              <ReportTable />
              <dashboard-navigation />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
import DashboardNavigation from '@/components/dashboard/DashboardNavigation.vue'
import ReportTable from '@/components/dashboard/ReportTable.vue'

export default {
  name: 'Home',
  components: {
    DashboardNavigation,
    ReportTable
  },
  methods: {
  }
}
</script>
