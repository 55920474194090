<template>
    <div
      v-bind:style="{ 'background-image': `url('${backgroundUrl}')`, 'background-size': backgroundSize, 'background-position': backgroundPosition }"
      :class="mobile ? 'header-section-mobile': 'header-section'"
    >
      <div style="width: 100%; height: 100%;">
        <div :class="mobile ? 'header-text-mobile' : 'header-text'">
          <v-img v-if="award" width="30%" style="margin-bottom: 40px" src="/home/peoples_choice.png"/>
          <div :class="mobile ? 'header-title-mobile' : 'header-title'">{{title}}</div>
          <div :class="mobile ? 'section-paragraph-mobile header-description-mobile' : 'section-paragraph header-description'">{{description}}</div>
        </div>
        <div v-if="(headerButtons.length > 0 && !mobile)" class="header-buttons">
          <v-btn
            x-large
            v-for="item in headerButtons"
            :key="item"
            class="header-button-active"
            style="margin-right: 5px"
            @click="scrollToElement(item)"
          >
            {{ item }}
          </v-btn>
        </div>
      </div>
      </div>
</template>

<script>
    export default {
        name: 'AppBanner',
        props: ['backgroundUrl', 'title', 'description', 'headerButtons', 'backgroundSize', 'backgroundPosition', 'award'],
        data: () => ({
          mobile: null,
          windowWidth: null
        }),
        created() {
          window.addEventListener('resize', this.checkScreen);
          this.checkScreen();
        },
        destroy() {
          window.removeEventListener('resize', this.checkScreen)
        },
        methods: {
            scrollToElement(e) {
                var element = document.getElementById(e)
                if (element) {
                  var headerOffset = 80;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition = elementPosition - headerOffset + window.pageYOffset;

                  window.scrollTo({
                      top: offsetPosition,
                      behavior: 'smooth'
                  })
                }
            },
            checkScreen() {
              this.windowWidth = window.innerWidth;
              if (this.windowWidth <= 1000) {
                this.mobile = true;
              } else {
                this.mobile = false;
              }
            }
          }
    }
</script>