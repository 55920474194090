export const i18next_hi = {
    navigation: {
      home: 'घर',
      map: 'नक्श',
      stats: 'आँकड़',
      dataset: 'डेटासेट',
      home_link: "/",
      map_link: "/map?lang=hi",
      stats_link: "/stats?lang=hi",
      reports_link: "/reports?lang=hi",
      home_upper: 'घर',
      map_upper: 'नक्श',
      stats_upper: 'आँकड़',
      dataset_upper: 'डेटासेट'
    },
    map: {
      last_7_days_tag: 'पिछले 7 दिन',
      last_30_days_tag: 'पिछले 30 दिन',
      range_tag: 'श्रेणी',
      start_date_tag: 'आरंभ तिथि',
      end_date_tag: 'समाप्ति तिथि',
      apply_tag: 'लाग',
      filter_tag: 'फ़िल्टर',
      filter_by_disease: 'रोग द्वारा फ़िल्टर करे',
      filter_by_syndrome: 'सिंड्रोम द्वारा फ़िल्टर करे',
      filter_by_tag: 'रोग/सिंड्रोम द्वारा फ़िल्टर करे',
      legend_tag: 'प्रतीक',
      diseases_tag: 'रोग',
      syndromes_tag: 'सिंड्रोम',
      report_tag: 'रिपोर्ट',
      reports_tag: 'रिपोर्ट',
      map_background: 'नक्शा पृष्ठभूमि',
      street_map_bg: 'मार्ग मानचित्र',
      topo_map_bg: 'स्थलाकृतिक मानचित्र',
      imagery_map_bg: 'छवि',
      light_map_bg: 'हल्के',
      dark_map_bg: 'गहरे',
      chart_colours: 'चार्ट रंग',
      bright_chart: 'चमक',
      general_search: 'सामान्य खोज',
      diseases_selected: 'चयनित {{val, number}} बीमारियों',
      syndromes_selected: 'चयनित {{val, number}} सिंड्रोम (ओं)',
      all_diseases: 'सभी रोग',
      all_diseases_upper: 'सभी रोग',
      clear: 'साफ़',
      loading: 'लोड हो रहा है कृपया प्रतीक्षा करें'
    },
    stats: {
      country_tag: 'देश',
      start_date_tag: 'आरंभ तिथि',
      end_date_tag: 'समाप्ति तिथि',
      diseases_tag: 'रोग',
      syndromes_tag: 'सिंड्रोम',
      disease_top_10_tag: 'शीर्ष 10 रोग',
      apply_tag: 'लाग',
      legend_tag: 'प्रतीक',
      bar_chart_date_tag: 'तिथि',
      bar_chart_reports_tag: 'रिपोर्ट',
      country_distribution_tag: 'देश वितरण फैलाव',
      regional_distribution_tag: 'क्षेत्रीय वितरण फैलाव',
      distribution_within_tag: '{{val, string}} के भीतर वितरण फैलाव ',
      reports_for_tag: '{{val, string}} के लिए रिपोर्ट ',
      disease_top_10_hover: 'यदि ऑन है, तो स्वचालित रूप से देश के शीर्ष 10 बीमारियों का चयन  समय सीमा का  भीतर करेगा। अन्यथा, चयनित रोग अपरिवर्तित रहेंगे।',
      initial_stacked_bar_chart_title: "ऑस्ट्रेलिया के लिए रिपोर्ट",
      initial_country_chart_title: "ऑस्ट्रेलिया के भीतर वितरण फैलाव",
      initial_region_chart_title: "ऑस्ट्रेलिया और न्यूजीलैंड के भीतर वितरण फैलाव",
      diseases_selected: 'चयनित {{val, number}} बीमारियों',
      syndromes_selected: 'चयनित {{val, number}} सिंड्रोम (ओं)'
    },
    dataset: {
      date_tag: 'तिथि',
      diseases_tag: 'रोग',
      syndromes_tag: 'सिंड्रोम',
      country_tag: 'देश',
      location_tag: 'स्थान'
    }
  };