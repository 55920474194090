import Vue from 'vue'
import VueRouter from 'vue-router'
import ReportsTable from '../views/ReportsTable.vue'
import About from '../views/About.vue'
import Home from '../views/Home.vue';
import Products from '../views/Products.vue'
import Publications from '../views/Publications.vue'
import ReportMap from '../views/Map.vue'
import Contact from '../views/Contact.vue'
import Stats from '../views/Stats.vue'
import Smallpox from '../views/InternalMedia/Smallpox.vue';
import PageNotFound from '../views/PageNotFound.vue'
// import WeeklyDigest from '../views/WeeklyDigest.vue'
// import DashboardPlaceholder from '../views/DashboardPlaceholder.vue'
import EpiscopePlaceholder from '../views/EpiscopePlaceholder.vue'
import Profile from '../views/Profile.vue'
// import Flucast from '../views/FlucastPlaceholder.vue'
import Media from '../views/Media.vue'
// import BlockIE from '../views/BlockIE.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/publications',
    name: 'Publications',
    component: Publications
  },
  {
    path: '/reports',
    name: 'Reports Table',
    component: ReportsTable
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/map',
    name: 'Map',
    component: ReportMap
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats
  },
  {
    path: '/episcope',
    name: 'EPISCOPE',
    component: EpiscopePlaceholder
  },
  {
    path: '/profile',
    name: 'Member Profile',
    component: Profile
  },
  // {
  //   path: '/flucast',
  //   name: 'Flucast',
  //   component: Flucast
  // },
  {
    path: '/media-releases',
    name: 'Media',
    component: Media
  },
  {
    path: '/media/smallpox',
    name: 'Smallpox',
    component: Smallpox
  },
  {
    path: '/media/EPISCOPE*.pdf',
    name: 'EPISCOPE',
    component: EpiscopePlaceholder
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: PageNotFound
  }
  // {
  //   path: '/about_epiwatch',
  //   name: 'About Us',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  // },
  // {
  //   path: '/termsOfUse',
  //   name: 'Terms of Use',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "terms" */ '../views/TermsOfUse.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  document.title = "EPIWATCH - " + to.name
  window.scrollTo(0, 0)
  next()
})

export default router
