<template>
    <v-container class="fill-height pa-0 white-section" max fluid>
            <div style="margin: 0 auto; margin-top: -100px;">
            <v-img src="/general/epiwatch-logo.svg" class="pa-10" max-height="200px" contain />
            <br/>
            <v-row justify="center" class="section-title">EPIWATCH does not support Internet Explorer.</v-row>
            <v-row justify="center" class="section-paragraph">Please use a different web browser to load the website.</v-row>
            </div>
        <app-footer style="position: absolute; width: 100%; bottom: 0; left: 0;" />
    </v-container>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'BlockIE',
  components: {
      AppFooter
  },
  methods: {
  }
}
</script>