<template>
  <div>
    <div :class="mobile ? 'blue-section-mobile' : 'blue-section'">
      <div style="width: 100%; max-width: 1500px; margin: 0 auto;">
        <div style="display: flex">
        <div style="flex: 1; background-color: white; padding: 10px; align-items: center;">
          <center><img src="/about/unsw-logo.png" :style="mobile ? 'width: 100%' : 'height: 100px;'"/></center>
        </div>
        <div style="flex: 1; background-color: white; padding: 10px; margin: 0px 15px;align-items: center;">
          <center><img src="/about/kirby-logo.png" :style="mobile ? 'width: 100%' : 'height: 100px;'"/></center>
        </div>
        <div style="flex: 1; padding: 10px; align-items: center; text-align: right;" :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph;'">
  Project RFRHPI000280 received funding
  from the Australian Government and Balvi Filantropic Fund. 
        </div>
      </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-copyright">
          <a href="https://twitter.com/EPIWATCH_bsp" target="_blank"><img height="20px" src="/general/twitter-logo.svg" style="margin-right: 5px"/></a>
          <a href="https://www.facebook.com/ISER-Integrated-Systems-for-Epidemic-Response-1113681065343096" target="_blank"><img height="20px" src="/general/facebook-logo.svg" style="margin-right: 5px"/></a>
          <a href="https://www.youtube.com/channel/UC9Q177fKqLbBVxFBfR8NtOA" target="_blank"><img height="20px" src="/general/youtube-logo.svg"/></a>
      </div>
      <div :class="mobile ? 'footer-copyright-mobile' : 'footer-copyright'">© 2023 EPIWATCH. All rights reserved.</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppFooter',
    data: () => ({
      mobile: null,
      windowWidth: null
    }),
    created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
        }
      }
  }
</script>
