<template>
  <div>
    <v-app-bar v-if="mobile" dark dense color="#19214c"
      style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 500;">
      <v-tabs show-arrows centered icons-and-text>
        <v-tab v-for="item in navItems" :key="item.itemName" :to="$t(item.linkTo)" height="38px"
          style="font-size: 0.6em;" v-bind:class="[get_route_name == item.routeName ? 'active-class' : null]"><v-icon>{{
      item.itemIcon }}</v-icon>{{ $t(item.itemName) }}</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-navigation-drawer v-if="!mobile" permanent dark color="#19214c" app>
      <div class="px-6"><a :href="$t(navItems[0].linkTo)"><v-img src="/general/epiwatch-logo-2.svg" class="pa-10"
            height="150px" contain /></a></div>
      <v-list dense>
        <v-list-item :to="$t(navItems[1].linkTo)" link>
          <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.map") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="$t(navItems[2].linkTo)" link>
          <v-list-item-action>
            <v-icon>mdi-signal</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.stats") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="$t(navItems[3].linkTo)" link>
          <v-list-item-action>
            <v-icon>mdi-table</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.dataset") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="button">
          <v-btn-toggle v-model="language">
            <v-btn small value="en" @click="refreshPageLang('en')">English</v-btn>
            <v-btn small value="hi" @click="refreshPageLang('hi')">हिन्दी</v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import i18next from "i18next";


const moment = require('moment')


export default {
  name: 'DashboardNavigation',
  computed: {
    get_route_name() {
      try {
        return this.$route.name
      } catch (e) {
        return ""
      }
    }
  },
  data: () => ({
    language: i18next.language,
    navItems: [
      { itemName: "navigation.home_upper", routeName: "Home", linkTo: "navigation.home_link", itemIcon: 'mdi-home' },
      { itemName: "navigation.map_upper", routeName: "Map", linkTo: "navigation.map_link", itemIcon: "mdi-map-marker" },
      { itemName: "navigation.stats_upper", routeName: "Stats", linkTo: "navigation.stats_link", itemIcon: "mdi-signal" },
      { itemName: "navigation.dataset_upper", routeName: "Dataset", linkTo: "navigation.reports_link", itemIcon: "mdi-table" }
    ],
    monthAgo: moment().startOf('day').subtract(30, 'days').format("YYYY-MM-DD"),
    mobile: null,
    windowWidth: null
  }),
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    refreshPageLang(newLang) {
      const currRoute = this.$route;
      if (newLang == 'en') {
        this.$i18next.locale = 'en';

        this.$router.push({
          path: `${currRoute.path}`
        });
      } else {
        this.$i18next.locale = 'hi'
        this.$router.push({
          path: `${currRoute.path}?lang=${newLang}`
        });
      }
      window.location.reload();
    }
  },
  created() {
    this.language = this.$route.query.lang == 'hi' ? 'hi' : 'en';
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener('resize', this.checkScreen)
  }
}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}

.v-btn {
  text-transform: none;
  background-color: #19214c;
}
.button {
  background-color: #19214c;
  justify-content: center;
  display: flex;
  padding-bottom: 1vh;
}
</style>