<template>
    <div>
        <app-navigation/>
        <app-banner
          backgroundUrl="/press/logos/smallpox.jpeg"
          :headerButtons="headerButtons"
          backgroundSize="cover"
          backgroundPosition="0% 10%"
          title="Smallpox lessons from the field, Raina Macintyre interviews Dr J Michael Lane"
        />
        <div style="text-align: center; background-color: #e6e8e8; min-height: 50vh;">
            <iframe allowfullscreen="" border="10" frameborder="0" height="44" src="https://thebox.unsw.edu.au/unswMP3/embed?v=LvkXMAC/EeiUydoDzrP4Ag==&amp;a=0&amp;k=&amp;w=540&amp;h=30&amp;i=0&amp;ii=default#start=&amp;stop=" style="margin:5vh" width="540"></iframe>
            <div>Dr J Michael Lane is Emeritus Professor of Preventive Medicine at Emory University School of Medicine, Atlanta, Georgia, USA. His research interests are smallpox, adverse events after smallpox vaccination, and smallpox vaccination policy. He was formerly Director of Smallpox Eradication at US CDC. He is also an alumnus of the US CDC’s Epidemic Intelligence Service and has extensive international public health and field epidemiology experience. He established the <a href="https://rsph.anu.edu.au/study/master-degrees/master-philosophy-applied-epidemiology">Australian Field Epidemiology Training Program in 1990</a> , when he took up the position of inaugural Director of the program. This has now grown to boast more than 200 graduates, who have investigated over 300 outbreaks.  </div>
        </div>
      <app-footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppBanner from '@/components/AppBanner.vue'

export default {
  name: 'Smallpox',
  components: {
      AppNavigation,
      AppFooter,
      AppBanner
  },
  data: () => ({
    headerButtons: [],
  })
}
</script>