<template>
    <div>
        <app-navigation/>
        <app-banner
          backgroundUrl="/press/media-header.jpeg"
          :headerButtons="headerButtons"
          backgroundSize="cover"
          title="Media and press"
          description=""
        />
        <div style="text-align: center; background-color: #e6e8e8;">
          <div :class="mobile ? 'white-section-mobile' : 'white-section'" style="width: 100%; max-width: 1500px; margin: 0 auto; height: 100%;">
            <div :class="mobile ? 'section-title-mobile' : 'section-title'" id="Social Media">Follow us on social media</div>
            <div>
              <a href="https://twitter.com/EPIWATCH_bsp" target="_blank"><img height="40px" src="/general/twitter-logo-blue.svg" style="margin-right: 20px"/></a>
              <a href="https://www.facebook.com/ISER-Integrated-Systems-for-Epidemic-Response-1113681065343096" target="_blank"><img height="40px" src="/general/facebook-logo-blue.svg" style="margin-right: 20px"/></a>
              <a href="https://www.youtube.com/channel/UC9Q177fKqLbBVxFBfR8NtOA" target="_blank"><img height="40px" src="/general/youtube-logo-blue.svg"/></a>
            </div>
          </div>
          <div :class="mobile ? 'blue-section-mobile' : 'blue-section'">
            <div style="max-width: 1500px; margin: 0 auto;">
              <v-row>
              <v-col :cols="mobile ? '12' : '8'">
              <v-carousel v-model="videoCarousel" style="max-width: 800px; margin: 0 auto;">
                <v-carousel-item v-for="video in videos" :key="video">
                  <iframe width="100%" height="80%" :src="video.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          <v-card style="background-color: #213d8c;" class="media-footer pa-2">
                          <span style="font-size: small;" class="white--text">{{ video.des }}</span>
                          </v-card>
                </v-carousel-item>
              </v-carousel>
              </v-col>
              <v-col :cols="mobile ? '12' : '4'">
              <a class="twitter-timeline" data-width="400" data-height="500" href="https://twitter.com/EPIWATCH_bsp?ref_src=twsrc%5Etfw">Tweets by EPIWATCH_bsp</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
              </v-col>
              </v-row>
            </div>
          </div>
          <div :class="mobile ? 'white-section-mobile' : 'white-section'" style="width: 100%; max-width: 1500px; margin: 0 auto; height: 100%;">
            <div :class="mobile ? 'section-title-mobile' : 'section-title'" id="Press">Press</div>
            <a :v-if="!loading" v-for="item in media" :key="item.link"  :href="item.link" target="_blank" style="width: calc(100% + 50px); margin-left: -50px; text-align: left;">
            <v-card hover style="height: 100%; background-color: white; text-transform: none !important; font-weight: none !important;" :style="mobile ? 'padding: 20px;' : 'padding: 50px;'">
              <v-row>
                <v-col :cols="mobile ? '12' : '3'" style="display: flex; justify-content: center; align-items: center;">
                  <v-img :src="item.logo" />
                </v-col>
                <v-col :cols="mobile ? '12' : '9'" style="display: flex; align-items: center;">
                  <div>
                    <div :class="mobile ? 'section-title-mobile' : 'section-title'" style="width: 100%; text-decoration: underline; color: #213d8c; ">{{item.title}}</div>
                    <div :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph'" style="width: 100%;">{{item.date}} | <b>{{item.source}}</b></div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            </a>
          </div>
        </div>
        <app-footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppBanner from '@/components/AppBanner.vue'
import axios from 'axios'

export default {
  name: 'Media',
  components: {
      AppNavigation,
      AppFooter,
      AppBanner
  },
  data: () => ({
      mobile: null,
      windowWidth: null,
      headerButtons: ['Social Media', 'Press'],
      videoCarousel: 0,
      videos: [
        {url: "https://www.youtube.com/embed/u9JEuL6mCcw", des: "Introduction to Epiwatch"},
        {url: "https://www.youtube.com/embed/KH4Qulb8Tok", des: "EPIWATCH Launches Hindi Version"},
        {url: "https://www.youtube.com/embed/CpL6HxUYDC8", des: "Professor Raina MacIntyre on a panel at The Brookings Institute Panel on biosafety and COVID-19 origins."}
      ],
      media: null,
      loading: true
    }),
  created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    async mounted() {
      this.checkScreen();
      this.fetch_media().then((data) => {
        this.media = data
        this.loading = false
      })
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
        return;
      },
      fetch_media() {
        return new Promise((resolve, reject) => {
          axios.get("data/media.json").then((response) => {
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {})
        })
      }
    }
  
}
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}

a {
  text-decoration: none;
}
</style>