<template>
  <div>
  <app-navigation />
  <div class="white-section" :style="mobile ? 'margin-top: 40px; padding-top: 50px;' : 'margin-top: 60px; padding-top: 70px;'">
    <div style="width: 100%; max-width: 1500px; margin: 0 auto; height: 100%; margin-bottom: -30px;">
    <div v-if="!person">
        <div class="section-title">Page Not Found</div>
        <v-btn x-large class="red-button" to="/">Return to home</v-btn>
    </div>
    <div v-if="person && !mobile" style="width: 100%; display: flex; flex-direction: row; color: #051238; margin-bottom: 30px;">
      <div
        style="flex: 3; position: relative; background-color: white; padding: 50px;"
      >
      <div :id="'inner-description'" style="min-width: 300px;">
        <div class="section-title" >{{profiles[person].name}}</div>
        <div class="section-paragraph">{{profiles[person].job}}</div>
        <br/>
        <div class="section-paragraph">{{profiles[person].description}}
        </div>
        <br/>
</div>
      </div>
      <div
      :id="'image-right'"
        style="
          flex: 2;
          background-color: gray;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: right center;
          height: inherit;
        "
        v-bind:style="{ 'background-image': `url('${profiles[person].img}')`}"
      >
        <svg
          :id="'image-wave'"
          xmlns="http://www.w3.org/2000/svg"
          style="float: left;"
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 12 843 800"
        >
          <path
            fill="#fff"
            fill-opacity="1"
            d="M 489.537 1373.669 L 478.361 1340.811 C 466.792 1308.022 445.066 1242.397 395.957 1180.992 C 347.364 1120.207 272.406 1063.664 260.598 996.697 C 248.617 928.745 299.663 849.68 298.392 780.855 C 296.606 711.41 243.384 651.441 199.593 589.098 C 156.497 527.242 122.919 463.504 121.77 393.643 C 120.777 324.667 150.175 249.521 133.443 183.422 C 116.554 116.437 51.549 58.748 19.571 28.847 L -12.627 0 L -12.627 0 L -6.671 33.779 C 0 67.558 0 134.919 0 202.871 C 0 270.133 0 337.789 0 404.756 C 0 472.708 0 540.66 0 607.626 C 0 675.283 0 742.545 0 801.899 C 0 877.858 0 803.899 0 997.899 C 0 1160.899 0 997.899 0 1148.899 C 0 1340.899 0 1148.899 0 1307.899 L 0 1373.669 Z"
          ></path>
        </svg>
      </div>
    </div>
    <div v-if="person && mobile" style="width: 100%; display: flex; color: #051238; padding-bottom: 30px;">
      <v-card style="margin: 20px 10px; align-items: center;">
              <div
        style="
          height: 250px;
          width: 100%;
          
          background-color: gray;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        "
        v-bind:style="{ 'background-image': `url('${profiles[person].img}')`}"
      >
        </div>
          <div style="position: relative; background-color: white; padding: 15px; display: flex; align-items: center;">
            <div>
            <br/>
            <div class="section-title" >{{profiles[person].name}}</div>
            <div class="section-paragraph">{{profiles[person].job}}</div>
            <br/>
            <div class="section-paragraph">{{profiles[person].description}}
            </div>
            <br/>
            </div>
          </div>
      </v-card>
    </div>
      
    </div>
  </div>
  <app-footer />
  </div>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'Profile',
  components: {
      AppNavigation,
      AppFooter
  },
  data: () => ({
      person: null,
      mobile: null,
      profiles: {
          daniel_fowler: { name: 'Daniel Fowler', job: 'PhD Student | Biosecurity Research Program\nKirby Institute | UNSW Sydney', img: '/about/team photos/daniel.jpg', description: 'Daniel is working on geospatial analysis for the EPIWATCH team. This involves finding emerging outbreaks of particular diseases.' },
          allan_burr: { name: 'Allan Burr', job: 'GIS Engineer | Biosecurity Research Program\nKirby Institute | UNSW Sydney', img: '/about/team photos/allan.jpg', description: 'Allan has completed a Master’s degree in Computer Science (Machine Learning and Big Data Analytics). With experience in geospatial data analytics, machine learning and software engineering, he works as a GIS engineer on the EPIWATCH system for Kirby Institute’s Biosecurity Program.'},
          damian_honeyman: { name: 'Damian Honeyman', job: 'Senior Research Officer | Biosecurity Research Program\nKirby Institute | UNSW Sydney', img: '/about/team photos/damian.jpeg', description: 'Damian is working as a Senior Research Officer in the Biosecurity Program at the Kirby Institute. He is a member of the research team working with quantitative and qualitative research data on several projects and the EPIWATCH Global Observatory, which provides daily outbreak updates to inform rapid epidemic response globally. He recently completed his Master of Global Health/Master of Infectious Diseases Intelligence (extension) at UNSW with a focus on disease surveillance and Intelligence, where he completed research led by Professor Raina MacIntyre and completed an internship at the Biosecurity Program. Before becoming a Senior Research Officer, Damian worked in the Sydney Public Health Unit and as an Intensive care nurse within the South Eastern Sydney Local Health District.'},
          adriana_notaras: { name: 'Adriana Notaras', job: 'Senior Project Coordinator | Biosecurity Research Program\nKirby Institute | UNSW Sydney', img: '/about/team photos/adriana.jpg', description: 'Adriana is a Senior Project Coordinator at The Kirby Institute and is involved in numerous clinical research studies involving vaccine trials and COVID-19 transmission in the Intensive Care Unit and works as an analyst for the EPIWATCH system. Adriana has a background in Intensive Care Nursing and emergency public health response units.'},
          chenxi_guo: { name: 'Chenxi (Richard) Guo', job: 'Software Engineer | Biosecurity Research Program\nKirby Institute | UNSW Sydney', img: '/about/team photos/chenxi.jpg', description: 'Richard is currently a software engineer for EPIWATCH. His passion is building, creating, designing and debugging.'}
      }
  }),
  created() {
    const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("person") in this.profiles) {
          this.person = urlParams.get("person")
      }
      window.addEventListener('resize', this.checkScreen);
    },
  mounted() {
      this.checkScreen();
  },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
          if (document.getElementById("inner-description")) {
            let innerSectionHeight = document.getElementById("inner-description").clientHeight;
            if (document.getElementById("image-right")) {
              document.getElementById("image-right").setAttribute("style", "flex: 2; max-height: calc("+innerSectionHeight+"px + 100px);background-color: gray; position: relative; background-size: cover; background-repeat: no-repeat; background-position: center; height: inherit; background-image: url('"+this.profiles[this.person].img+"')")
              document.getElementById("image-right").style.maxHeight = "calc(" + innerSectionHeight + "px + 100px)"
            }
            if (document.getElementById("image-wave")) {
              document.getElementById("image-wave").setAttribute("style", "float: left; height: calc("+innerSectionHeight+ "px + 100px);")
              document.getElementById("image-wave").style.height = "calc(" + innerSectionHeight + "px + 100px)"
            }
          }
    }
    }
}
</script>