
<template>
  <div>
    <v-app-bar
    absolute
    elevate-on-scroll
    :shrink-on-scroll="mobile ? false : true"
    :height="mobile ? '50px' : '80px'"
    class="top-navbar"
    background-size="contain"
    src="/header-wave.svg"
    style="zIndex: 5; "
    >
    <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :width="mobile ? '200px' : '400px'"
          position="left bottom"
          aspect-ratio="2"
        ></v-img>
      </template>
      <a href="/" :style="mobile ? 'height: 100%; width: 75px;' : 'height: 100%; width: 120px; margin-left: 30px'">
        <img src="/general/epiwatch-logo.svg" height="100%">
      </a>
      <v-spacer></v-spacer>
      <v-tabs v-show="!mobile" right hide-slider append class="top-navbar-tabs">
          <v-tab 
            v-for="item in navItems" 
            :key="item.itemName" 
            :to="item.linkTo" 
            class="top-navbar-tab" 
            v-bind:class="[ 
              get_route_name == item.routeName ? 'top-navbar-tab-active' : null, item.itemName == 'DASHBOARD' 
              ? 
              'top-navbar-tab-dashboard' : null 
            ]">{{item.itemName}}</v-tab>
          <a 
            href='https://ui.epiwatch.app/map' 
            class="top-navbar-tab v-tab top-navbar-tab-dashboard" >
          {{ "DASHBOARD" }}
          </a>



      </v-tabs>
      <v-app-bar-nav-icon v-show="mobile" class="top-navbar-menu-icon" @click.stop="toggleMobileNav" v-bind:class="[ mobileNav ? 'top-navbar-menu-icon-active' : null ]"></v-app-bar-nav-icon>
    </v-app-bar>
            <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          color="rgba(217, 59, 56)"
          dismissible
          dark
          style="position:fixed; bottom: 10px; right: 10px; z-index: 50; max-width: 450px; font-size: 0.9em; margin-left: 10px;"
        >
          If you would like to give feedback on the EPIWATCH website, please complete <a target="_blank" href="https://forms.gle/BxynzGoRSasaBrRJ7" style="color: white; text-decoration: underline;">this short survey</a>.
        </v-alert>
        <v-navigation-drawer temporary v-model="mobileNav" app light color="#e6e8e8">
        <v-list>
          <v-list-item v-for="item in navItems" :key="item.itemName" :to="item.linkTo" class="mobile-navbar-item" v-bind:class="[ get_route_name == item.routeName ? 'mobile-navbar-item-active' : null ]">
            <v-list-item-title class="mobile-navbar-title">{{item.itemName}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </div>
</template>

<script>

  export default {
    name: 'AppNavigation',
    computed: {
      get_route_name() {
        try {
          return this.$route.name
        } catch(e) {
          return ""
        }
      }
    },
    data: () => ({
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      navItems: [
        { itemName: "HOME", routeName: "Home", linkTo: "/" },
        { itemName: "ABOUT", routeName: "About", linkTo: "/about" },
        { itemName: "PRODUCTS", routeName: "Products", linkTo: "/products" },
        { itemName: "PUBLICATIONS", routeName: "Publications", linkTo: "/publications" },
        { itemName: "MEDIA", routeName: "Media", linkTo: "/media-releases" },
        { itemName: "CONTACT", routeName: "Contact", linkTo: "/contact" },
      ]
    }),
    created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
    methods: {
      toggleMobileNav() {
        this.mobileNav = !this.mobileNav;
      },
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1000) {
          this.mobile = true;
        } else {
          this.mobile = false;
          this.mobileNav = false;
        }
      }
    }

  }
</script>

<style>
.v-toolbar__content {
  margin: 0 auto;
}
</style>