<template>
  <v-app>
    <v-main>
      <router-view v-if="!isIE"/>
      <block-internet-explorer v-if="isIE" />
    </v-main>
  </v-app>
</template>

<script>
// import AppNavigation from '@/components/AppNavigation';
// import AppFooter from '@/components/AppFooter';
import BlockIE from '@/views/BlockIE.vue';

export default {
  name: 'App',

  components: {
    // AppNavigation,
    // AppFooter
    'block-internet-explorer': BlockIE
  },

  data: () => ({
    isIE: false
  }),
  methods: {
    detectIE() {
      let ua = window.navigator.userAgent;

      let msie = ua.indexOf('MSIE ');
      if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      let trident = ua.indexOf('Trident/');
      if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      return false
    }
  },
  created() {
    this.isIE = this.detectIE()
    // this.isIE = 11
  } 
};
</script>

<style lang="scss">
  @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
  @import "./assets/epiwatch-theme.css"
</style>