import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Chartkick from 'vue-chartkick' // chart.js
import Chart from 'chart.js' // chart.js
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import { i18next_en } from './locales/en/i18next.locale.en';
import { i18next_hi } from './locales/hi/i18next.locale.hi';

Vue.config.productionTip = false;
Vue.config.silent = true;

const locales = {
  en: i18next_en,
  hi: i18next_hi
}

i18next.init({
  lng: 'hi',
  fallbackLng: "en",
  resources: {
    en: {
      translation: locales.en
    },
    hi: {
      translation: locales.hi
    }
  }
});

Vue.use(Chartkick.use(Chart));
Vue.use(I18NextVue, { i18next });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')