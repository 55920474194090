<template>
  <div>
    <div v-if="mobile" class="white-section-mobile">
      <div style="width: 100%; max-width: 1500px; margin: 0 auto; mobile-bottom: -20px;">
        <div id="Team" class="section-title-mobile" style="text-align: center; margin-bottom: 20px;">Meet the EPIWATCH
          team</div>
        <img height="100px" src="/about/epiwatch_team.jpg" />
        <div>
          <div v-for="teamGroup in teamMembers" :key="teamGroup.group"
            style="display: flex; flex-wrap: wrap; flex-basis: 100%; flex: 2; border: 3px solid #051238; position: relative; "
            :style="mobile ? 'margin-bottom: 20px; padding: 10px;' : 'margin-bottom: 50px; padding: 50px;'">
            <div style="position: absolute; top: -12px; left: 50%;">
              <div class="section-paragraph-mobile"
                style="position: relative; left:-50%; background-color: #e6e8e8; font-weight: bold; padding: 0 5px; text-align: center;">
                {{ teamGroup.group }}</div>
            </div>
            <div v-if="mobile" style="width: 100%;">
              <v-card hover v-for="member in teamGroup.members" :key="member.name"
                :href="member.profile ? member.profile : null" target="_blank"
                style="margin: 20px 10px; align-items: center;">
                <div style="
          height: 150px;
          width: 100%;
          
          background-color: gray;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        " v-bind:style="{ 'background-image': `url('${member.img}')` }">
                </div>
                <div
                  style="position: relative; background-color: white; height: 100%; padding: 15px; display: flex; align-items: center;">
                  <div>
                    <div class="section-paragraph-mobile" style="font-weight: bold">{{ member.name }}</div>
                    <div class="section-paragraph-mobile" style="white-space: pre-line; font-size: 0.9375em;">
                      {{ member.job }}</div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!mobile" class="white-section">
      <div style="width: 100%; max-width: 1500px; margin: 0 auto; margin-bottom: -50px;">
        <div id="Team" class="section-title" style="text-align: center; margin-bottom: 50px;">Meet the EPIWATCH team
        </div>
        <img width="100%" src="/about/epiwatch_team.jpg" />
        <div v-if="!loading" style="margin-top: 40px">

          <div v-for="teamGroup in teamMembers" :key="teamGroup.group"
            style="display: flex; flex-wrap: wrap; flex-basis: 100%; flex: 2; border: 3px solid #051238; margin-bottom: 50px; padding: 50px; position: relative; ">
            <div style="position: absolute; top: -12px; left: 50%;">
              <div class="section-paragraph"
                style="position: relative; left:-50%; background-color: #e6e8e8; font-weight: bold; padding: 0 5px;">
                {{ teamGroup.group }}</div>
            </div>
            <v-card hover v-for="member in teamGroup.members" :key="member.name" :href="member.profile" target="_blank"
              style="display: flex; height: 150px; margin: 5px;align-items: center;"
              :style="{ 'width': teamMobile || teamGroup.group == 'LEAD INVESTIGATOR' ? '100%' : '49%' }">
              <div
                style="flex: 4; position: relative; background-color: white; height: 100%; padding: 15px; display: flex; align-items: center;">
                <div>
                  <div class="section-paragraph" style="font-weight: bold">{{ member.name }}</div>
                  <div class="section-paragraph" style="white-space: pre-line; font-size: 0.9375em;">{{ member.job }}
                  </div>
                </div>
              </div>
              <div style="
          height: 150px;
          flex: 2;
          background-color: gray;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: right center;
        " v-bind:style="{ 'background-image': `url('${member.img}')` }">
                <svg xmlns="http://www.w3.org/2000/svg" style="height: 100%; float: left"
                  preserveAspectRatio="xMinYMin meet" viewBox="0 12 843 592">
                  <path fill="#ffffff" fill-opacity="1"
                    d="M 489.537 1373.669 L 478.361 1340.811 C 466.792 1308.022 445.066 1242.397 395.957 1180.992 C 347.364 1120.207 272.406 1063.664 260.598 996.697 C 248.617 928.745 299.663 849.68 298.392 780.855 C 296.606 711.41 243.384 651.441 199.593 589.098 C 156.497 527.242 122.919 463.504 121.77 393.643 C 120.777 324.667 150.175 249.521 133.443 183.422 C 116.554 116.437 51.549 58.748 19.571 28.847 L -12.627 0 L -12.627 0 L -6.671 33.779 C 0 67.558 0 134.919 0 202.871 C 0 270.133 0 337.789 0 404.756 C 0 472.708 0 540.66 0 607.626 C 0 675.283 0 742.545 0 801.899 C 0 877.858 0 803.899 0 997.899 C 0 1160.899 0 997.899 0 1148.899 C 0 1340.899 0 1148.899 0 1307.899 L 0 1373.669 Z">
                  </path>
                </svg>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// @ is an alias to /src

export default {
  name: "Team",
  components: {
  },
  data: () => ({
    mobile: null,
    teamMobile: null,
    windowWidth: null,
    teamMembers: [],
    loading: true
  }),
  async mounted() {
    this.checkScreen();
    this.fetch_team().then((data) => {
      this.teamMembers = data
      this.loading = false
    })
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener("resize", this.checkScreen);
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 700) {
        this.mobile = true;
        this.teamMobile = true;
      } else if (this.windowWidth <= 1220) {
        this.mobile = false;
        this.teamMobile = true;
      } else {
        this.mobile = false;
        this.teamMobile = false;
      }
      return;
    },
    fetch_team() {
      return new Promise((resolve, reject) => {
        axios.get("/data/team-members.json").then((response) => {
          resolve(response.data)
        })
          .catch((e) => {
            reject(e)
          })
          .finally(() => { })
      })
    }
  },
};
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>