<template>
  <v-container>
    <v-card class="pa-5 mb-5">
      <v-container>
    <v-row justify="space-around">
      <v-col :cols="mobile ? '12' : '4'" style="margin: 0; padding: 0;">
      <v-autocomplete
        v-if="!mobile"
        v-model="selected_country"
        :items="countries"
        item-text="country_name"
        item-value="id"
        outlined
        dense
        :label="$t('stats.country_tag')"
        :class="!mobile ? 'pr-5' : null"
        :loading="countries.length == 0"
        @change="ui_changed"
      >
      </v-autocomplete>
      <v-select
        v-if="mobile"
        v-model="selected_country"
        :items="countries"
        item-text="country_name"
        item-value="id"
        outlined
        dense
        :menu-props="{ bottom: true, offsetY: true }"
        :label="$t('stats.country_tag')"
        :class="!mobile ? 'pr-5' : null"
        :loading="countries.length == 0"
        @change="ui_changed"
      >
      </v-select>
      </v-col>
      <v-spacer/>
      <v-col :cols="mobile ? '12' : '4'" style="margin: 0; padding: 0;">
      <v-menu v-model="start_date_menu" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="start_date"
            :label="$t('stats.start_date_tag')"
            v-bind="attrs"
            v-on="on"
            outlined
            :class="!mobile ? 'pr-5' : null"
            autocomplete="off"
            dense
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="start_date"
          no-title
          :min="monthAgo"
          :locale="$i18next.language"
          :max="today"
          @input="start_date_menu = false"
          @change="ui_changed"
        ></v-date-picker>
      </v-menu>
      </v-col>
      <v-spacer/>
      <v-col :cols="mobile ? '12' : '4'" style="margin: 0; padding: 0;">
      <v-menu v-model="end_date_menu" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="end_date"
            :label="$t('stats.end_date_tag')"
            v-bind="attrs"
            v-on="on"
            outlined
            autocomplete="off"
            dense
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="end_date"
          no-title
          :locale="$i18next.language"
          :min="monthAgo"
          :max="today"
          @input="end_date_menu = false"
          @change="ui_changed"
        ></v-date-picker>
      </v-menu>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-autocomplete
        v-if="!mobile"
        v-model="selected_diseases"
        :items="diseases"
        :allow-overflow="false"
        item-text="disease"
        item-value="id"
        outlined
        dense
        chips
        small-chips
        :label="$t('stats.diseases_tag')"
        multiple
        :loading="diseases.length == 0"
        @change="ui_changed"
      >
        <template v-slot:selection="data">
          <v-chip close small @click:close="unselect_disease_chip(data.item)" outlined>
            {{ data.item.disease }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-select
        v-if="mobile"
        v-model="selected_diseases"
        :items="diseases"
        :allow-overflow="false"
        item-text="disease"
        item-value="id"
        outlined
        dense
        chips
        small-chips
        :label="$t('stats.diseases_tag')"
        multiple
        :menu-props="{ bottom: true, offsetY: true }"
        :loading="diseases.length == 0"
        @change="ui_changed"
      >
        <template v-slot:selection="data">
          <div v-if="selected_diseases.length > 0">
            {{ selected_diseases.indexOf(data.item.id) == 0 ? $t("stats.diseases_selected", { val: selected_diseases.length }) : null }}
          </div>
        </template>
      </v-select>

    </v-row>
    <v-row justify="space-around">
      <v-autocomplete
        v-if="!mobile"
        v-model="selected_syndromes"
        :items="syndromes"
        :allow-overflow="false"
        item-text="syndrome"
        item-value="id"
        outlined
        dense
        chips
        small-chips
        :label="$t('stats.syndromes_tag')"
        multiple
        :loading="syndromes.length == 0"
        @change="ui_changed"
      >
        <template v-slot:selection="data">
          <v-chip close small @click:close="unselect_syndrome_chip(data.item)" outlined>
            {{ data.item.syndrome }}
          </v-chip>

        </template>
      </v-autocomplete>
      <v-select
        v-if="mobile"
        v-model="selected_syndromes"
        :items="syndromes"
        :allow-overflow="false"
        item-text="syndrome"
        item-value="id"
        outlined
        dense
        chips
        small-chips
        :label="$t('stats.syndromes_tag')"
        multiple
        :menu-props="{ bottom: true, offsetY: true }"
        :loading="syndromes.length == 0"
        @change="ui_changed"
      >
        <template v-slot:selection="data">
          <div v-if="selected_syndromes.length > 0">
            {{ selected_syndromes.indexOf(data.item.id) == 0 ? $t("stats.syndromes_selected", { val: selected_syndromes.length }) : null }}
          </div>
        </template>
      </v-select>
    </v-row>
    <v-row>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-switch
              v-model="auto_top10"
              :label="$t('stats.disease_top_10_tag')"
              class="pr-5 mt-1"
              @change="ui_changed"
            ></v-switch>
          </div>
        </template>
        <span
          >{{$t('stats.disease_top_10_hover')}}</span
        >
      </v-tooltip>
        <v-btn color="green" dense @click="apply" :disabled="apply_button_disabled"> {{$t("stats.apply_tag")}} </v-btn>
    </v-row>
      </v-container>
    </v-card>

    <v-row>
      <v-col md="2">
        <v-card :loading="legend_loading" height="100%">
          <v-card-title>{{$t("stats.legend_tag")}}</v-card-title>
          <v-card-text>
            <span v-if="applied_selections.length > 0">
              <span v-for="(name, index) in applied_selections" :key="name">
                <div>
                  <div
                    class="legend-disease-syndrome"
                    v-bind:style="{
                      'background-color': get_legend_colour(index),
                      'pointer-events': 'none',
                    }"
                  ></div>
                  <span style="padding: 5px; pointer-events: none">{{
                    name
                  }}</span>
                </div>
              </span>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="10">
        <v-card :loading="bar_chart_loading" height="100%">
          <v-card-title>{{ stacked_bar_chart_title }}</v-card-title>
          <v-card-text>
            <column-chart
              :xtitle="$t('stats.bar_chart_date_tag')"
              :ytitle="$t('stats.bar_chart_reports_tag')"
              :data="stacked_bar_chart_data"
              :legend="false"
              :stacked="true"
              :download="false"
              :colors="legend_colours"
            >
            </column-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card :loading="country_pie_chart_loading">
          <v-card-title>{{$t('stats.country_distribution_tag')}}</v-card-title>
          <v-card-subtitle v-text="country_title_text"></v-card-subtitle>
          <v-card-text>
            <pie-chart
              :data="country_pie_chart_data"
              :legend="false"
              :download="false"
              :colors="legend_colours"
            ></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card :loading="region_pie_chart_loading">
          <v-card-title>{{$t('stats.regional_distribution_tag')}}</v-card-title>
          <v-card-subtitle v-text="region_title_text"></v-card-subtitle>
          <v-card-text>
            <pie-chart
              :data="region_pie_chart_data"
              :legend="false"
              :download="false"
              :colors="legend_colours"
            ></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import axios from "axios";
import config from "../../config";
import i18next from 'i18next';
import { subregions_hi } from "../../locales/hi/subregions.locale.hi";
import { subregions_en } from "../../locales/en/subregions.locale.en";

const moment = require('moment');

export default {
  data() {
    return {
      stacked_bar_chart_data: [],
      diseases: [],
      syndromes: [],
      countries: [],
      selected_diseases: [],
      selected_syndromes: [],
      applied_selected_diseases: [],
      applied_selected_syndromes: [],
      applied_selections:[],
      selected_country: "11",
      country_pie_chart_data: [],
      region_pie_chart_data: [],
      start_date: "",
      end_date: "",
      start_date_menu: false,
      end_date_menu: false,
      stacked_bar_chart_title: this.$t("stats.initial_stacked_bar_chart_title"),
      country_title_text: this.$t("stats.initial_country_chart_title"),
      region_title_text: this.$t("stats.initial_region_chart_title"),

      country_pie_chart_loading: true,
      region_pie_chart_loading: true,
      bar_chart_loading: true,
      legend_loading: true,
      auto_top10: true,
      legend_colours: [ "#27b", "#f81", "#3a3", "#d23", "#96c", "#955", "#e7c", "#888", "#cc2", "#1cd","#27b", "#f81", "#3a3", "#d23", "#96c", "#955", "#e7c", "#888", "#cc2", "#1cd","#27b", "#f81", "#3a3", "#d23", "#96c", "#955", "#e7c", "#888", "#cc2", "#1cd","#27b", "#f81", "#3a3", "#d23", "#96c", "#955", "#e7c", "#888", "#cc2", "#1cd","#27b", "#f81", "#3a3", "#d23", "#96c", "#955", "#e7c", "#888", "#cc2", "#1cd", ],
      apply_button_disabled: true,
      mobile: null,
      windowWidth: null,
      monthAgo: moment().startOf('day').subtract(30,'days').format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      headerOptions: {
                    headers: {
                        "Accept-Language": this.$i18next.language
                    }
                }
    };
  },
  methods: {
    async apply() {
      this.apply_button_disabled = true;
      this.update_charts();
    },
    get_legend_colour(index) {
      return this.legend_colours[index];
    },
    ui_changed() {
      this.apply_button_disabled = false;
    },
    format_date(iso_date) {
      var d = DateTime.fromISO(iso_date);
      // return d.toFormat("d LLL yyyy");
      return d.toISODate();
    },
    async create_stacked_chart() {

      const disease_data = await this.fetch_diseases_in_range();

      this.stacked_bar_chart_data = [];
      this.stacked_bar_chart_title = this.$t("stats.reports_for_tag", {val: this.country_map_id_to_name[this.selected_country]});
      // we need to add the data to the stacked bar chart in the same order as it is displayed
      // in the legend to get the colours to match up, so that's why we iterate through the selected
      // diseases and then the selected syndromes
      for (var i = 0; i < this.selected_diseases.length; i++) {
        var disease_name = this.disease_map_id_to_name[this.selected_diseases[i]];
        var stacked_disease_data = [];
        for (const [date, disease_count] of Object.entries(disease_data)) {
          // console.log(JSON.parse(JSON.stringify(disease_count)));

          // if there's data for that disease add it, otherwise add a 0  
          if (disease_count && (disease_name in disease_count)) {
            stacked_disease_data.push([this.format_date(date), disease_count[disease_name]]);
          } else {
            stacked_disease_data.push([this.format_date(date), 0]);
          }
        }

        this.stacked_bar_chart_data.push({
          name: disease_name,
          data: stacked_disease_data,
        });
      }

      for (i = 0; i < this.selected_syndromes.length; i++) {
        var syndrome_name = this.syndrome_map_id_to_name[this.selected_syndromes[i]];
        var stacked_syndrome_data = [];
        for (const [date, syndrome_count] of Object.entries(disease_data)) {

          // if there's data for that syndrome add it, otherwise add a 0  
          if (syndrome_count && (syndrome_name in syndrome_count)) {
            stacked_syndrome_data.push([this.format_date(date), syndrome_count[syndrome_name]]);
          } else {
            stacked_syndrome_data.push([this.format_date(date), 0]);
          }
        }

        this.stacked_bar_chart_data.push({
          name: syndrome_name,
          data: stacked_syndrome_data,
        });
      }
      // console.log(JSON.parse(JSON.stringify(this.stacked_bar_chart_data)));
    },
    // gets the illness data for the country_ids given, and formats it for displaying as a pie chart
    async get_pie_chart_data(country_ids) {
      var pie_chart_data = [];

      var illess_data = await this.fetch_illnesses(country_ids);

      // we need to add the data to the pie chart in the same order as it is displayed
      // in the legend to get the colours to match up, so that's why we iterate through the selected
      // diseases and then the selected syndromes

      for (var i = 0; i < this.selected_diseases.length; i++) {
        var disease_name = this.disease_map_id_to_name[this.selected_diseases[i]];
        if (disease_name in illess_data) {
          pie_chart_data.push([disease_name, illess_data[disease_name]]);
        } else {
          pie_chart_data.push([disease_name, 0]);
        }
      }
      for (i = 0; i < this.selected_syndromes.length; i++) {
        var syndrome_name = this.syndrome_map_id_to_name[this.selected_syndromes[i]];
        if (syndrome_name in illess_data) {
          pie_chart_data.push([syndrome_name, illess_data[syndrome_name]]);
        } else {
          pie_chart_data.push([syndrome_name, 0]);
        }
      }
      // console.log("** formatted pie chart data");
      // console.log(JSON.parse(JSON.stringify(pie_chart_data)));
      return pie_chart_data;
    },
    async create_country_pie_chart() {
      this.country_pie_chart_data = await this.get_pie_chart_data([this.selected_country]);
    },
    async create_region_pie_chart() {
      this.region_pie_chart_data = [];

      // find out the country ids for the current region
      // console.log("Get region for", this.selected_country);
      // console.log("Get region for", this.country_map_id_to_iso3[this.selected_country]);
      // console.log("Get region for", this.region_map_iso3_to_region[this.country_map_id_to_iso3[this.selected_country]]);
      var region_name = this.region_map_iso3_to_region[this.country_map_id_to_iso3[this.selected_country]];
      var countries_in_region = this.regions[region_name];
      // console.log(countries_in_region);
      var countries_in_region_ids = [];
      for (var i = 0; i < countries_in_region.length; i++){
        if (countries_in_region[i] in this.country_map_iso3_to_id)
          countries_in_region_ids.push(this.country_map_iso3_to_id[countries_in_region[i]]);
      }
      // console.log(countries_in_region_ids);

      // console.log("Getting", countries_in_region_ids);

      this.region_pie_chart_data = await this.get_pie_chart_data(countries_in_region_ids);
    },
    select_top10_diseases(disease_data) {
      // console.log("getting top 10");
      this.selected_diseases = [];
      var disease_array = [];
      for (const [disease, count] of Object.entries(disease_data)) {
        disease_array.push({ name: disease, count: count });
      }
      disease_array.sort((a, b) => b.count - a.count);
      /// console.log(disease_array);
      // console.log(this.disease_map_name_to_id)
      for (var i = 0; i < 10 && i < disease_array.length; i++) {
        var disease_name = disease_array[i].name;
        this.selected_diseases.push(this.disease_map_name_to_id[disease_name]);
      }
    },
    unselect_disease_chip(item) {
      const index = this.selected_diseases.indexOf(item.id);
      if (index >= 0) {
        this.selected_diseases.splice(index, 1);
        this.apply_button_disabled = false;
      }
    },
    unselect_syndrome_chip(item) {
      const index = this.selected_syndromes.indexOf(item.id);
      if (index >= 0) {
        this.selected_syndromes.splice(index, 1);
        this.apply_button_disabled = false;
      }
    },
    async fetch_diseases() {
      return new Promise((resolve, reject) => {
        var url = config.api_url + 'api/disease';

        axios.get(url, this.headerOptions)
          .then((response) => response.data)
          .then((data) => {
              this.diseases = data

              this.disease_map_id_to_name = {};
              this.disease_map_name_to_id = {};
              for (var i = 0; i < this.diseases.length; i++) {
                  this.disease_map_id_to_name[this.diseases[i].id] = this.diseases[i].disease;
                  this.disease_map_name_to_id[this.diseases[i].disease] = this.diseases[i].id;
              }
              resolve()
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {});
      });
    },
    async fetch_syndromes() {
      return new Promise((resolve, reject) => {
        var url = config.api_url + 'api/syndrome';

        axios.get(url, this.headerOptions)
          .then((response) => response.data)
          .then((data) => {
              this.syndromes = data;
              this.syndrome_map_id_to_name = {};
              this.syndrome_map_name_to_id = {};
              for (var i = 0; i < this.syndromes.length; i++) {
                  this.syndrome_map_id_to_name[this.syndromes[i].id] = this.syndromes[i].syndrome;
                  this.syndrome_map_name_to_id[this.syndromes[i].syndrome] = this.syndromes[i].id;
              }
              resolve()
        })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    async fetch_countries() {
      return new Promise((resolve, reject) => {
        var url = config.api_url + 'api/countries'

        axios.get(url, this.headerOptions)
          .then((response) => response.data)
          .then((data) => {
            this.countries = data;
            this.country_map_iso3_to_id = {};
            this.country_map_id_to_iso3 = {};
            this.country_map_id_to_name = {};
            for (var i = 0; i < this.countries.length; i++) {
                this.country_map_iso3_to_id[this.countries[i].iso3] =
                    this.countries[i].id;
                this.country_map_id_to_iso3[this.countries[i].id] =
                    this.countries[i].iso3;
                this.country_map_id_to_name[this.countries[i].id] =
                    this.countries[i].country_name;
            }
            resolve()
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    fetch_country_diseases(country_id) {
      // console.log("getting disease by location");
      var url = config.api_url + 'api/reportDiseasesInLocation';
      var body = {
        filters: {
          start_date: this.start_date,
          end_date: this.end_date,
          country_id: country_id,
        },
      };
      // console.log(body);

      return axios.post(url, body, this.headerOptions).then((response) => response.data)
              .catch((e) => {
                let msg = ""
                if (e.response) {
                    if (e.response.data) {
                        msg = e.response.data
                    }
                }
                this.$app_helpers.show_error(msg + "<br />" + String(e))
      });
    },
    fetch_illnesses(country_ids) {
      // console.log("getting disease by location");
      var url = config.api_url + 'api/reportIllnessesInRegion';
      var body = {
        filters: {
          start_date: this.start_date,
          end_date: this.end_date,
          country_ids: country_ids,
        },
      };
      // console.log(body);

      return axios.post(url, body, this.headerOptions).then((response) => response.data )
              .catch((e) => {
                let msg = ""
                if (e.response) {
                    if (e.response.data) {
                        msg = e.response.data
                    }
                }
                this.$app_helpers.show_error(msg + "<br />" + String(e))
        });
    },
    fetch_diseases_in_range() {
      // console.log("getting disease by range");
        var fetch_promise;

        var url = config.api_url + 'api/reportDiseasesInRangeNew';
        var body = {
            filters: {
            start_date: this.start_date,
            end_date: this.end_date,
            country_ids: [this.selected_country]
            },
        };

        if (this.selected_diseases.length > 0)  
            body.filters.disease_ids = this.selected_diseases;
        if (this.selected_syndromes.length > 0)  
            body.filters.syndrome_ids = this.selected_syndromes;  

        // console.log("SENT", body)

        // console.log(body);
        fetch_promise = axios.post(url, body, this.headerOptions).then((response) => response.data)
                          .catch((e) => {
                            let msg = ""
                            if (e.response) {
                                if (e.response.data) {
                                    msg = e.response.data
                                }
                            }
                            this.$app_helpers.show_error(msg + "<br />" + String(e))
        });

        return fetch_promise;
    },
    set_default_dates() {
      var end = DateTime.now();
      var start = end.plus({ days: -30 });
      this.start_date = start.toISODate();
      this.end_date = end.toISODate();
    },
    async fetch_regions() {
      if (this.$i18next.language == 'hi') {
        this.regions = subregions_hi;
      } else {
        this.regions = subregions_en;
      }
      let temp = {}
      for (const [region, country_array] of Object.entries(this.regions)) {
        for (var i = 0; i < country_array.length; i++) {
          temp[country_array[i]] = region;
        }
      }
      this.region_map_iso3_to_region = temp;
    },
    async update_charts() {
      // set all the cards to their loading state
      this.country_pie_chart_loading = true;
      this.region_pie_chart_loading = true;
      this.bar_chart_loading = true;

      // set the subtitles on the two pie charts
      const country_name = this.country_map_id_to_name[this.selected_country];
      const country_iso3 = this.country_map_id_to_iso3[this.selected_country];
      const region_name = this.region_map_iso3_to_region[country_iso3];
      this.country_title_text = this.$t("stats.distribution_within_tag", {val: country_name});
      this.region_title_text = this.$t("stats.distribution_within_tag", {val: region_name});
        

      // if "auto top 10 diseases" is turned on, we need to get all diseases for the current country
      // and then find the top 10 from that list
      if (this.auto_top10) {
        let current_country_diseases = await this.fetch_country_diseases(this.selected_country);
        if (current_country_diseases["No results"] == 0) {
          current_country_diseases = { "covid19": 0 }
        }
        // console.log("TOP 10", current_country_diseases)
        this.select_top10_diseases(current_country_diseases);
      }

      // apply any changes that have been made to the UI since the last update
      this.applied_selected_diseases = JSON.parse(JSON.stringify(this.selected_diseases));
      this.applied_selected_syndromes = JSON.parse(JSON.stringify(this.selected_syndromes));

      // add the selected diseases and selected syndromes into 1 list called "applied_selections"
      // this is used to populate the legend card
      this.applied_selections = [];
      for (var i = 0; i < this.applied_selected_diseases.length; i++) {
        this.applied_selections.push(this.disease_map_id_to_name[this.applied_selected_diseases[i]]);
      }
      for (i = 0; i < this.applied_selected_syndromes.length; i++) {
        this.applied_selections.push(this.syndrome_map_id_to_name[this.applied_selected_syndromes[i]]);
      }
      this.legend_loading = false;
      // console.log(this.applied_selections);

      // create the stacked bar chart
      await this.create_stacked_chart();
      this.bar_chart_loading = false;

      // create the pie chart for the selected country
      await this.create_country_pie_chart();
      this.country_pie_chart_loading = false;

      // create the pie chart for the region that the selected country is in
      await this.create_region_pie_chart();
      this.region_pie_chart_loading = false;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  },
  async mounted() {
    let lang = ['en', 'hi'].includes(this.$route.query.lang) ? this.$route.query.lang : 'en';
    if (this.$i18next.language !== lang) {
      i18next.changeLanguage(lang);
    }
    this.headerOptions = {
                    headers: {
                        "Accept-Language": this.$i18next.language
                    }
                };
    this.stacked_bar_chart_title = this.$t("stats.initial_stacked_bar_chart_title");
    this.country_title_text = this.$t("stats.initial_country_chart_title");
    this.region_title_text = this.$t("stats.initial_region_chart_title");

    this.set_default_dates();
    this.fetch_countries().then(() => {
      return this.fetch_diseases()
    }).then(() => {
      return this.fetch_syndromes()
    }).then(() => {
      return this.fetch_regions()
    }).then(() => {
      this.update_charts();
    })
  },
  created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    },
    destroy() {
      window.removeEventListener('resize', this.checkScreen)
    },
};
</script>

<style>
.legend-disease-syndrome {
  height: 10px;
  width: 10px;
  margin-right: 2px;
  border-color: 3px solid black;
  display: inline-block;
}
</style>