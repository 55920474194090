<template>
  <div>
    <app-navigation />
    <app-banner
      backgroundUrl="/about/about-header-4.jpeg"
      :headerButtons="headerButtons"
      backgroundSize="cover"
      backgroundPosition="0% 10%"
      title="AI-driven open-source outbreak observatory"
      description="Our team of experts will help you detect and mitigate epidemics today for a secure future tomorrow."
    />
    <about-vision/>
    <mission-section/>
    <team-section/>
    <app-footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from "@/components/AppNavigation.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppBanner from "@/components/AppBanner.vue";
import MissionSection from "@/components/about/Mission.vue"
import AboutVision from "@/components/about/AboutVision.vue";
import TeamSection from "@/components/about/Team.vue"

export default {
  name: "About",
  components: {
    AppNavigation,
    AppFooter,
    AppBanner,
    MissionSection,
    AboutVision,
    TeamSection
  },
  data: () => ({
    mobile: null,
    windowWidth: null,
    activeSection: "Vision",
    headerButtons: ['Vision', 'Mission', 'Team']
  }),
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    this.checkScreen()
  },
  destroy() {
    window.removeEventListener("resize", this.checkScreen);
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }

      if (document.getElementById("vision-description")) {
        let innerSectionHeight = document.getElementById("vision-description").clientHeight;
        innerSectionHeight = innerSectionHeight > 300 && !this.mobile ? 300 : innerSectionHeight;
        if (document.getElementById("vision-wave")) {
          document.getElementById("vision-wave").setAttribute("style", "float: left; height: calc("+innerSectionHeight+ "px);")
          document.getElementById("vision-wave").style.height = "calc(" + innerSectionHeight + "px)"
        }
        if (document.getElementById("vision-image")) {
          document.getElementById("vision-image").setAttribute("style", "flex: 3; position: relative; background-image: linear-gradient(90deg, rgba(230,232,232,0) 85%, rgba(230,232,232,1) 100%), url('/about/vision-1.png'); background-size: cover; background-repeat: no-repeat; background-position: center; max-height: calc("+innerSectionHeight+ "px);")
          document.getElementById("vision-image").style.maxHeight = "calc(" + innerSectionHeight + "px)"
        }
      }
      return;
    },
    selectSection(id) {
      this.activeSection = id;
      return;
    }
  },
};
</script>

<style>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>