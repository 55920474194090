<template>
  <div>
  <app-navigation />
  <div class="white-section" style="margin-top: 60px; padding-top: 70px; height: calc(100vh - 148px) !important;">
    <div class="section-title">Page Not Found</div>
    <v-btn x-large class="red-button" to="/">Return to home</v-btn>
  </div>
  <app-footer />
  </div>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'PageNotFound',
  components: {
      AppNavigation,
      AppFooter
  }
}
</script>