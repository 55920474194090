<template>
  <div class="map-container">
    <div>
      <v-toolbar dark>
        <!-- Settings menu -->  
        <v-menu :offset-y="true" :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn small dark v-on="on" class="mr-5"><v-icon>mdi-cog</v-icon></v-btn>
          </template>
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>{{$t("map.map_background")}}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-radio-group v-model="menu_map_background">
                  <v-radio :label="$t('map.street_map_bg')" value="Esri_WorldStreetMap" />
                  <v-radio :label="$t('map.topo_map_bg')" value="Esri_WorldTopoMap" />
                  <v-radio :label="$t('map.imagery_map_bg')" value="Esri_WorldImagery" />
                  <v-radio :label="$t('map.light_map_bg')" value="esri_worldgraycanvas_light" />
                  <v-radio :label="$t('map.dark_map_bg')" value="esri_worldgraycanvas_dark" />
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>{{$t("map.chart_colours")}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group v-model="chart_colour_palette">
                  <v-radio :label="$t('map.bright_chart')" value="bright" />
                  <v-radio :label="$t('map.light_map_bg')" value="light" />
                  <v-radio :label="$t('map.dark_map_bg')" value="dark" />
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-menu>

        <!-- Date range selector -->
        <v-btn-toggle v-if="!mobile" v-model="date_range_mode" mandatory class="mr-5">
          <v-btn small value="7day">{{$t("map.last_7_days_tag")}}</v-btn>
          <v-btn small value="30day">{{$t("map.last_30_days_tag")}}</v-btn>
          <v-btn small value="custom">{{$t("map.range_tag")}}</v-btn>
        </v-btn-toggle>

        <span v-if="!mobile && date_range_mode == 'custom'">
          <v-menu
            v-model="date_range_start_menu"
            :close-on-content-click="false"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-on="on"
                v-bind="attrs"
                v-model="date_range_start"
                :label="$t('map.start_date_tag')"
                dense
                class="mt-3 mr-4 date-input"
                readonly
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_range_start"
              :locale="$i18next.language"
              :min="monthAgo"
              :max="today"
              @input="date_range_start_menu = false"
            ></v-date-picker>
          </v-menu>
        </span>
        <span v-if="date_range_mode == 'custom'">
          <v-menu
            v-model="date_range_end_menu"
            :close-on-content-click="false"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-on="on"
                v-bind="attrs"
                v-model="date_range_end"
                :label="$t('map.end_date_tag')"
                dense
                class="mt-3 date-input"
                readonly
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_range_end"
              :min="monthAgo"
              :max="today"
              :locale="$i18next.language"
              @input="date_range_end_menu = false"
            ></v-date-picker>
          </v-menu>
        </span>
        <span v-if="date_range_mode == 'custom'">
          <v-btn
            small
            light
            class="ml-2"
            color="grey lighten-3"
            @click="do_api_update"
            >{{$t('map.apply_tag')}}</v-btn
          >
        </span>

        <!-- Disease and syndrome selector -->
        <v-menu
          bottom
          :close-on-content-click="false"
          offset-y
          v-model="disease_syndrome_menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="ml-auto">
              <span class="d-flex flex-row">
                <v-btn
                  small
                  :color="
                    get_filter_text == ''
                      ? 'grey lighten-1'
                      : 'orange lighten-4'
                  "
                  light
                  v-bind="attrs"
                  v-on="on"
                  >{{mobile ? $t('map.filter_tag') : $t('map.filter_by_tag') + get_filter_text }}
                </v-btn>
              </span>
            </span>
          </template>

          <v-card :style="!mobile ? 'min-width: 400px' : null">
            <v-card-text>
              <span v-if="mobile" class="ml-auto">
                <span class="d-flex flex-row">
                  <v-btn-toggle v-model="date_range_mode" mandatory class="mr-5">
                    <v-btn small value="7day">{{$t("map.last_7_days_tag")}}</v-btn>
                    <v-btn small value="30day">{{$t("map.last_30_days_tag")}}</v-btn>
                  </v-btn-toggle>
                </span>
              </span>
              <span v-if="!mobile" class="ml-auto">
                <span class="d-flex flex-row">
                <v-text-field
                  v-model="filter_term"
                  small
                  :label="$t('map.general_search')"
                  append-icon="mdi-magnify"
                  :error-messages="(filter_term.length >= 2 && selected_diseases.length == 0 && selected_syndromes.length == 0) ? 'No matching disease/syndrome found' : ''"
                ></v-text-field>
                </span>
              </span>
              <v-autocomplete
                v-if="!mobile"
                dense
                class="pt-7"
                v-model="selected_diseases"
                :locale="$i18next.language"
                :items="map_diseases"
                :allow-overflow="false"
                chips
                small-chips
                :label="$t('map.diseases_tag')"
                :placeholder="$t('map.filter_by_disease')"
                item-text="name"
                item-value="name"
                :item-disabled="(el) => checkItemDisabled(el)"
                @input="diseaseSearchText = null"
                :search-input.sync="diseaseSearchText"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    active-class="blue-pill"
                    :color="(data.item.name == allDiseases) ? 'orange lighten-2' : null"
                    close
                    small
                    @click="data.select"
                    @click:close="unselect_disease(data.item)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
              <v-select
                v-if="mobile"
                dense
                class="pt-7"
                v-model="selected_diseases"
                :items="map_diseases"
                :locale="$i18next.language"
                :allow-overflow="false"
                chips
                small-chips
                :menu-props="{ bottom: true, offsetY: true }"
                :label="$t('map.diseases_tag')"
                :placeholder="$t('map.filter_by_disease')"
                item-text="name"
                item-value="name"
                :item-disabled="(el) => checkItemDisabled(el)"
                @input="diseaseSearchText = null"
                :search-input.sync="diseaseSearchText"
                multiple
              >
                <template v-slot:selection="data">
                  <div selected_diseases.length >
                    {{ data.item.name == allDiseases ? $t("map.diseases_tag", { val: map_diseases.length - 1 }) : null }}
                    {{ data.item.name != allDiseases && selected_diseases.indexOf(data.item.name) == 0 ? $t("map.diseases_tag", { val: selected_diseases.length }) : null }}
                  </div>
                </template>
              </v-select>

              <v-autocomplete
                v-if="!mobile"
                :class="!mobile ? 'pt-7' : null"
                v-model="selected_syndromes"
                :items="map_syndromes"
                :allow-overflow="false"
                chips
                small-chips
                :label="$t('map.syndromes_tag')"
                :placeholder="$t('map.filter_by_syndrome')"
                item-text="name"
                item-value="name"
                @input="syndromeSearchText = null"
                :search-input.sync="syndromeSearchText"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    active-class="blue-pill"
                    close
                    small
                    @click="data.select"
                    @click:close="unselect_syndrome(data.item)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
              <v-select
                v-if="mobile"
                :class="!mobile ? 'pt-7' : null"
                v-model="selected_syndromes"
                :items="map_syndromes"
                :allow-overflow="false"
                chips
                small-chips
                :menu-props="{ bottom: true, offsetY: true }"
                :label="$t('map.syndromes_tag')"
                :placeholder="$t('map.filter_by_syndrome')"
                item-text="name"
                item-value="name"
                @input="syndromeSearchText = null"
                :search-input.sync="syndromeSearchText"
                multiple
              >
                <template v-slot:selection="data">
                  <div selected_syndromes.length >
                    {{ selected_syndromes.indexOf(data.item.name) == 0 ? selected_syndromes.length + " syndrome(s) selected" : null }}
                  </div>
                </template>
              </v-select>
            </v-card-text>
            <v-card-actions class="">
              <span class="ml-auto">
                <v-btn
                  v-if="!mobile"
                  color="orange lighten-2"
                  dark
                  small
                  @click="apply_all_disease_filter()"
                  >{{$t('map.all_diseases_upper')}}</v-btn
                >
                <v-btn
                  color="green lighten-2"
                  class="ml-3"
                  dark
                  small
                  @click="apply_disease_syndrome_filter()"
                  >{{$t('map.apply_tag')}}</v-btn
                >
                <v-btn
                  class="ml-3"
                  color="red lighten-2"
                  dark
                  small
                  @click="clear_disease_syndrome_filter()"
                  >{{$t("map.clear")}}</v-btn
                >
              </span>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-toolbar>
    </div>

    <!--------- MAP START -------->
    <div v-if="map_diseases.length == 0">
      <div>{{$t("map.loading")}}</div>
    </div>
    <div v-else class="map-wrapper">
      <l-map
        :options="{ zoomControl: false, attributionControl: false }"
        @ready="map_loaded"
        ref="mainmap"
        :zoom="zoom"
        :maxZoom="16"
        :center="center"
        @update:bounds="add_svg_listeners"
        class="leaflet-map"
        v-bind:style="{ 'background-color': backColor[current_theme] }"
        v-bind:worldCopyJump="true"
      >
        <l-control-zoom position="topleft"> </l-control-zoom>

        <l-tile-layer
          :url="tile_providers[current_tile_provider]['url']"
          :attribution="tile_providers[current_tile_provider]['attribution']"
          :options="tile_providers[current_tile_provider]['options']"
        />
        <l-control position="topright">
          <!-- <div><b>Legend</b></div> -->
          <v-expansion-panels accordion :value="mobile ? null : 0">
            <v-expansion-panel>
              <v-expansion-panel-header>{{$t('map.legend_tag')}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="legend">
                  <span v-if="selected_diseases_syndromes_filter.length > 0">
                    <span
                      v-for="name in selected_diseases_syndromes_filter"
                      :key="name"
                    >
                      <div
                        @mouseover="legend_mouseover"
                        @mouseout="legend_mouseout"
                      >
                        <div
                          class="legend-disease-syndrome"
                          v-bind:style="{
                            'background-color': get_chart_colour(name),
                            'pointer-events': 'none',
                          }"
                        ></div>
                        <span style="padding: 5px; pointer-events: none">{{
                          name
                        }}</span>
                      </div>
                    </span>
                  </span>
                  <span v-if="selected_diseases_syndromes_filter.length <= 0">
                    <div>No diseases/syndromes were selected.</div>
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </l-control>

        <l-control-attribution position="bottomleft"></l-control-attribution>
      </l-map>
      <div v-if="!finished_data_load" class="loading-screen">
        <div class="loading-logo-wrapper loading-logo-animate">
          <v-img
            src="/general/epiwatch-logo-map-2.png"
          />
        </div>
      </div>

      <!-- The pie chart tooltip that shows when you hover over slices in the pie charts -->
      <div class="leaflet-container leaflet-tooltip leaflet-tooltip-pane pie-chart-tooltip" ref="pie_chart_tooltip">
        <div class="label">label</div>
        <div class="count">count</div>
      </div>

      <!-- The status bar at the bottom of the screen -->
      <div class="status-bar-container">
        <!-- style="position: absolute; left: 50%; bottom:30px; z-index:401;"> -->
        <div class="status-bar" ref="status_bar" @click="status_bar_click">
          Showing reports for country. Click here or press ESC to return to main
          map.
        </div>
      </div>
    </div>
    <!--------- MAP END -------->
  </div>
</template>





<script>
// FIXME : for dev
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import "leaflet/dist/leaflet.css";
import axios from "axios";
import config from "../../config";

import L from "leaflet";
import { latLng, Icon } from "leaflet";
import {
  LMap,
  LControlZoom,
  LTileLayer,
  LControlAttribution,
  LControl,
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import i18next from 'i18next';

import * as d3 from "d3";
import polylabel from "polylabel";

const moment = require("moment");
const randomcolor = require("randomcolor");

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Map",
  components: {
    LMap,
    LControlZoom,
    LTileLayer,
    LControlAttribution,
    LControl,
  },
  data() {
    let ref = this;
    return {
      clusterOptions1: {
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html: "<div><span>" + cluster.getChildCount() + "</span></div>",
            className: "cluster-div",
            iconSize: new L.Point(50, 50),
          });
        },
      },
      clusterOptions2: {
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            // html: ref.create_donut_chart(cluster),
            html: ref.d3_pie_chart_data(cluster),
            className: "cluster-div2",
            iconSize: ref.d3_pie_chart_icon_size(cluster),
          });
        },
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
      },
      map_data: [],
      disease_syndrome_menu: false,
      selected_diseases: [],
      selected_diseases_filter: [],
      selected_syndromes: [],
      selected_syndromes_filter: [],
      selected_diseases_syndromes_filter: [],
      diseaseSearchText: "",
      syndromeSearchText: "",
      date_range_mode: "7day",
      date_range_start: "", // FIXME : for dev, gets results quicker
      date_range_start_menu: false,
      date_range_end: "", // FIXME : for dev, gets results quicker
      date_range_end_menu: false,
      finished_data_load: false,
      current_tile_provider: "Esri_WorldImagery",
      current_theme: "us_imagery_topo",
      cluster_colors: {},
      get_diseases: [],
      get_syndromes: [],
      sub_country_state: false,
      sub_country_markers: [],
      sub_country_cluster: L.markerClusterGroup(),
      tile_providers: {
        esri_worldgraycanvas_dark: {
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}",
          attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
          options: { maxZoom: 16 },
        },
        esri_worldgraycanvas_light: {
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
          attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
          options: { maxZoom: 16 },
        },
        stadia_alidade_smooth: {
          dark: {
            url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
            attribution:
              '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
            options: { maxZoom: 20 },
          },
          light: {
            url: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
            attribution:
              '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
            options: { maxZoom: 20 },
          },
        },
        Esri_WorldStreetMap: {
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012",
          options: { maxZoom: 20 },
        },
        Esri_WorldTopoMap: {
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          attribution:
            "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community",
          options: { maxZoom: 20 },
        },
        Esri_WorldImagery: {
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
          options: { maxZoom: 20 },
        },
        Esri_WorldGrayCanvas: {
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
          attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
          options: { maxZoom: 16 },
        },
        usgs: {
          us_imagery_topo: {
            url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
            attribution:
              'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>',
            options: { maxZoom: 20 },
          },
        },
      },
      enableTooltip: true,
      zoom: 3,
      center: [48, -1.219482],
      backColor: { dark: "#222222", light: "#c1c9cc" },
      fillColor: { dark: "#333333", light: "#f2f3f0" },
      lineColor: { dark: "#6d6d6d", light: "#AAA" },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: latLng(47.41322, -1.219482),
      countryOutlineByCountry: {},
      geojsonByCountry: {},
      continents: {
        "Africa": { countries: [] },
        "Asia": { countries: [] },
        "Europe": { countries: [] },
        "North America": { countries: [] },
        "Oceania": { countries: [] },
        "South America": { countries: [] },
      },
      regions: {},
      regionalMarkerClusters: {},
      svg_hover_scale: 1.2,
      menu_map_background: "Esri_WorldImagery",
      chart_colour_mode: "randomcolor",
      chart_colour_palette: "bright",
      d3_colour_scale : {},
      map_state: "normal",
      map_diseases: [],
      map_syndromes: [],
      filter_term: "",
      mobile: null,
      windowWidth: null,
      touchDevice: null,
      activeTouchEvent: false,
      monthAgo: moment().startOf('day').subtract(30,'days').format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      allDiseases: 'ALL DISEASES',
      headerOptions: {
                    headers: {
                        "Accept-Language": this.$i18next.language
                    }
                }
    };
  },
  computed: {
    get_filter_text() {
      let filter_count = 0;
      filter_count += (this.selected_diseases_filter[0] == this.allDiseases) ? this.map_diseases.length - 1 : this.selected_diseases_filter.length;
      filter_count += this.selected_syndromes_filter.length;
      if (filter_count > 0) {
        return "(" + filter_count + ")";
      } else {
        return "";
      }
    },
    currLang() {
      return this.$18next.language;
    }
  },
  watch: {
    disease_syndrome_menu: function (new_value) {
      if (new_value == false) {
        this.selected_diseases = [...this.selected_diseases_filter];
        this.selected_syndromes = [...this.selected_syndromes_filter];
        this.filter_term = ""
      }
      // console.log("disease_syndrome_menu", new_value);
    },
    date_range_mode: function () {
      if (this.date_range_mode == "7day") {
        this.date_range_start = moment()
          .startOf("day")
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        this.date_range_end = "";
      } else if (this.date_range_mode == "30day") {
        this.date_range_start = moment()
          .startOf("day")
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.date_range_end = "";
      } else if (this.date_range_mode == "custom") {
        this.date_range_start = "";
        this.date_range_end = "";
      }
      if (!this.mobile) {
        this.do_api_update();
      }
    },
    get_syndromes: function() {
      this.map_syndromes = this.get_syndromes.map((el) => {
        let new_syndrome = { ...el, syndrome: el['syndrome'].toLowerCase() }
        return new_syndrome
      })
    },
    get_diseases: function () {
      // console.log("get_diseases has changed");
      this.map_diseases = this.get_diseases.map((el) => {
        let new_disease = { ...el, name: el['disease'].toLowerCase() }
        return new_disease
      })
      // console.log(this.map_diseases)
      this.map_diseases.unshift({name: this.allDiseases})
      this.apply_all_disease_filter();
      this.date_range_mode = "7day";
      this.date_range_start = moment()
        .startOf("day")
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      this.finished_data_load = true;
      this.apply_disease_syndrome_filter();
      this.get_cluster_colors();
    },
    menu_map_background: function (newValue) {
    //   console.log("Set map to ", newValue);
      this.menu_set_map_background(this.tile_providers[newValue]);
    },
    chart_colour_palette: function (newValue) {
    //   console.log("Set chart cols to ", newValue);
      this.menu_set_chart_palette(newValue);
    },
    selected_diseases: function() {
      let flag = true
      this.selected_diseases.map((el) => flag = flag && el != this.allDiseases);
      // console.log(this.selected_diseases, flag)
      if (!flag && this.selected_diseases.length > 1) {
        this.selected_diseases = [this.allDiseases]
      }
    },
    filter_term: function() {
      if (this.disease_syndrome_menu) {
        let filter_diseases = []
        let filter_syndromes = []
        if (this.filter_term.length >= 2) {
          let filter_term = this.filter_term.toLowerCase()
          this.map_diseases.map((el) => {
            if (el.name.indexOf(filter_term) >= 0) {
              filter_diseases.push(el.name)
            }
          })
          this.map_syndromes.map((el) => {
            if (el.name.indexOf(filter_term) >= 0) {
              filter_syndromes.push(el.name)
            }
          })
        } 
        this.selected_diseases = filter_diseases
        this.selected_syndromes = filter_syndromes
      }
    }
  },
  methods: {
    checkItemDisabled(item) {
      return this.selected_diseases[0] == this.allDiseases && item.name != this.allDiseases
    },
    menu_set_chart_palette(palette) {
    //   console.log("Setting palette to ", palette);
      // this.chart_colour_mode = "randomcolor";
      this.chart_colour_palette = palette;
      this.get_cluster_colors();
      this.refresh_marker_clusters();
    },
    menu_set_map_background(tileset) {
    //   console.log("Setting map tileset to ", tileset);
      // remove previous tile layer
      this.$refs.mainmap.mapObject.eachLayer(function (layer) {
        if (layer instanceof L.TileLayer) {
          layer.remove();
        }
      });

      L.tileLayer(tileset.url, tileset).addTo(this.$refs.mainmap.mapObject);
    },
    refresh_marker_clusters() {
      for (const region in this.regionalMarkerClusters) {
        this.regionalMarkerClusters[region].refreshClusters();
      }
      this.add_svg_listeners();
    },

    // MARKER CLUSTER CREATION FUNCTIONS
    get_cluster_colors() {
      // console.log("getting cluster colours");
      //assigns a unique and random color to each disease/syndrome
      let total_disease_syndrome = {};
      this.map_diseases.map((el) => {
        if (el.name != this.allDiseases) {
          total_disease_syndrome[el.name] = "";
        }
      });
      this.map_syndromes.map(
        (el) => (total_disease_syndrome[el.name] = "")
      );

      let disease_syndrome_keys = Object.keys(total_disease_syndrome);
      let colors = randomcolor({
        count: disease_syndrome_keys.length,
        luminosity: this.chart_colour_palette,
        format: "rgb",
        seed: 1,
      });
      for (let i = 0; i < disease_syndrome_keys.length; i++) {
        total_disease_syndrome[disease_syndrome_keys[i]] = colors[i];
      }
      this.cluster_colors = total_disease_syndrome;

      switch (this.chart_colour_palette) {
        case 'bright' : 
          this.d3_colour_scale = d3.scaleOrdinal(d3.schemeCategory10);
          break;
        case 'light' : 
          this.d3_colour_scale = d3.scaleOrdinal(d3.schemePastel1);
          break;
        case 'dark' : 
          this.d3_colour_scale = d3.scaleOrdinal(d3.schemeDark2);
          break;
      }
      // force the d3 scale to be in the order of the legend, not in the order of usage in pie charts
      for (var index in this.selected_diseases_syndromes_filter)
      {
        this.get_chart_colour(this.selected_diseases_syndromes_filter[index]);
      }
      // console.log(this.selected_diseases_syndromes_filter)  
    },
    create_donut_chart: function (cluster) {
      // creates donut chart marker for clusters
      const clusterMarkers = cluster.getAllChildMarkers();
      let counts = {};
      let cluster_report_count = 0;

      const populate_donut_counts = (marker_points, names) => {
        Object.keys(marker_points).map((el) => {
          if (names.includes(el)) {
            if (counts[el] == undefined) {
              counts[el] = marker_points[el];
            } else {
              counts[el] += marker_points[el];
            }
          }
        });
      };

      // loop through markers of given cluster
      // counts the number of reports of each filtered disease/syndrome across the cluster
      // also keeps track of the cluster's combined report count
      let disease_names = []
      if (this.selected_diseases_filter[0] == this.allDiseases) {
        disease_names = this.map_diseases.map((disease) => { if (disease.name != this.allDiseases) return disease.name });
      } else {
        disease_names = [...this.selected_diseases_filter]
      }
      for (let i = 0; i < clusterMarkers.length; i++) {
        let marker_diseases = clusterMarkers[i].options.data_object.diseases;
        let marker_syndromes = clusterMarkers[i].options.data_object.syndromes;
        populate_donut_counts(marker_diseases, disease_names);
        populate_donut_counts(marker_syndromes, this.selected_syndromes_filter);
        cluster_report_count +=
          clusterMarkers[i].options.data_object.report_count;
      }

      let offsets = [];
      let total = 0;
      let sorted_counts_keys = Object.keys(counts).sort();
      sorted_counts_keys.map((key) => {
        offsets.push(total);
        total += counts[key];
      });

      var fontSize =
        total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
      var r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      var r0 = Math.round(r * 0.6);
      var w = r * 2;

      var html =
        '<div><svg width="' +
        w +
        '" height="' +
        w +
        '" viewbox="0 0 ' +
        w +
        " " +
        w +
        '" text-anchor="middle" style="font: ' +
        fontSize +
        'px sans-serif">';

      for (let i = 0; i < sorted_counts_keys.length; i++) {
        let count_key = sorted_counts_keys[i];
        html += this.create_donut_segment(
          offsets[i] / total,
          (offsets[i] + counts[count_key]) / total,
          r,
          r0,
          this.cluster_colors[count_key]
        );
      }
      html +=
        '<circle cx="' +
        r +
        '" cy="' +
        r +
        '" r="' +
        r0 +
        '" fill="#1a1a1a" fill-opacity="0.4"/><text dominant-baseline="central" fill="white" transform="translate(' +
        r +
        ", " +
        r +
        ')">' +
        cluster_report_count.toLocaleString() +
        "</text></svg></div>";

      var el = document.createElement("div");
      el.innerHTML = html;
      return el.firstChild;
    },
    create_donut_segment: function (start, end, r, r0, color) {
      // builds each part of the donut chart cluster by disease/syndrome
      if (end - start === 1) end -= 0.00001;
      var a0 = 2 * Math.PI * (start - 0.25);
      var a1 = 2 * Math.PI * (end - 0.25);
      var x0 = Math.cos(a0),
        y0 = Math.sin(a0);
      var x1 = Math.cos(a1),
        y1 = Math.sin(a1);
      var largeArc = end - start > 0.5 ? 1 : 0;

      return [
        '<path d="M',
        r + r0 * x0,
        r + r0 * y0,
        "L",
        r + r * x0,
        r + r * y0,
        "A",
        r,
        r,
        0,
        largeArc,
        1,
        r + r * x1,
        r + r * y1,
        "L",
        r + r0 * x1,
        r + r0 * y1,
        "A",
        r0,
        r0,
        0,
        largeArc,
        0,
        r + r0 * x0,
        r + r0 * y0,
        '" fill="' + color + '" />',
      ].join(" ");
    },
    get_chart_colour: function (disease_name) {
      if (this.chart_colour_mode === "randomcolor")
        return this.cluster_colors[disease_name];
      else if (this.chart_colour_mode === "d3")
        return this.d3_colour_scale(disease_name);
    },
    get_chart_colour_d3: function (d) {
      return this.get_chart_colour(d.data.label);
    },
    d3_pie_chart_data(cluster) {
      // console.log("Cluster has", cluster.getChildCount(), "children");
      // creates donut chart marker for clusters
      const clusterMarkers = cluster.getAllChildMarkers();
      let counts = {};
      // let cluster_report_count = 0;

      const populate_donut_counts = (marker_points, names) => {
        Object.keys(marker_points).map((el) => {
          if (names.includes(el.toLowerCase())) {
            if (counts[el.toLowerCase()] == undefined) {
              counts[el.toLowerCase()] = marker_points[el];
            } else {
              counts[el.toLowerCase()] += marker_points[el];
            }
          }
        });
      };

      // loop through markers of given cluster
      // counts the number of reports of each filtered disease/syndrome across the cluster
      // also keeps track of the cluster's combined report count
      let selected_disease_names = []
      if (this.selected_diseases_filter[0] == this.allDiseases) {
        selected_disease_names = this.map_diseases.map((disease) => { if (disease.name != this.allDiseases) return disease.name });
      } else {
        selected_disease_names = [...this.selected_diseases_filter]
      }
      for (let i = 0; i < clusterMarkers.length; i++) {
        let marker_diseases = clusterMarkers[i].options.data_object.diseases;
        let marker_syndromes = clusterMarkers[i].options.data_object.syndromes;
        populate_donut_counts(marker_diseases, selected_disease_names);
        populate_donut_counts(marker_syndromes, this.selected_syndromes_filter);
        // cluster_report_count += clusterMarkers[i].options.data_object.report_count;
      }

      let offsets = [];
      let total = 0;
      let sorted_counts_keys = Object.keys(counts).sort();
      sorted_counts_keys.map((key) => {
        offsets.push(total);
        total += counts[key];
      });
      // console.log(counts);
      // console.log(sorted_counts_keys);
      var pie_data = [];
      for (const [key, value] of Object.entries(counts)) {
        pie_data.push({ label: key, count: value });
      }
      // console.log(pie_data);
      // return cluster.getChildCount();

      var fontSize =
        total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
      var radius =
        total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      var width = radius * 2 * this.svg_hover_scale; // leave some space for the highlighted sections to "pop out"
      var height = width;

      // var width = 100, height = 100, margin = 2;
      // var radius = Math.min(width, height) / 2 - margin;

      var svgDom = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      svgDom.setAttributeNS(
        "http://www.w3.org/2000/xmlns/",
        "xmlns",
        "http://www.w3.org/2000/svg"
      );
      svgDom.setAttributeNS(
        "http://www.w3.org/2000/xmlns/",
        "xmlns:xlink",
        "http://www.w3.org/1999/xlink"
      );

      // var color = d3.scaleOrdinal(d3.schemeCategory10);

      var svg = d3
        .select(svgDom)
        .attr("pointer-events", "auto")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      var arc = d3
        .arc()
        .innerRadius(radius * 0.6) // none for pie chart
        .outerRadius(radius); // size of overall chart

      var pie = d3
        .pie() // start and end angles of the segments
        .value(function (d) {
          return d.count;
        }) // how to extract the numerical data from each entry in our dataset
        .sort(null); // by default, data sorts in oescending value. this will mess with our animation so we set it to null

      // creating the chart
      svg
        .selectAll("path") // select all path elements inside the svg. specifically the 'g' element. they don't exist yet but they will be created below
        .data(pie(pie_data)) //associate dataset wit he path elements we're about to create. must pass through the pie function. it magically knows how to extract values and bakes it into the pie
        .enter() //creates placeholder nodes for each of the values
        .append("path") // replace placeholders with path elements
        .attr("d", arc) // define d attribute with arc function above
        // .attr('fill', function(d) { return color(d.data.label); }) // use color scale to define fill of each label in dataset
        .attr("fill", this.get_chart_colour_d3)
        .style("pointer-events", "auto")
        .attr("class", "cluster-mouse-events")
        .attr("data-label", function (d) {
          return d.data.label;
        })
        .attr("data-count", function (d) {
          return d.data.count;
        });

      svg
        .append("circle")
        .attr("fill", "black")
        .attr("opacity", "0.5")
        .attr("class", "cluster-centre-circle")
        .attr("cx", "0")
        .attr("cy", "0")
        .attr("r", radius * 0.6);

      svg
        .append("text")
        .attr("fill", "white")
        .attr("font-size", fontSize)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .html(total);

      var chartHTML = svgDom.outerHTML;

      // return '<div style="margin-left: -' + width / 2 + 'px; margin-top: -' + height / 2 + 'px;">' + chartHTML + '</div>';
      return chartHTML;
    },
    d3_pie_chart_icon_size(cluster) {
      // console.log("Cluster has", cluster.getChildCount(), "children");
      // creates donut chart marker for clusters
      const clusterMarkers = cluster.getAllChildMarkers();
      let counts = {};
      // let cluster_report_count = 0;

      const populate_donut_counts = (marker_points, names) => {
        Object.keys(marker_points).map((el) => {
          if (names.includes(el)) {
            if (counts[el] == undefined) {
              counts[el] = marker_points[el];
            } else {
              counts[el] += marker_points[el];
            }
          }
        });
      };

      // loop through markers of given cluster
      // counts the number of reports of each filtered disease/syndrome across the cluster
      // also keeps track of the cluster's combined report count
      let selected_disease_names = []
      if (this.selected_diseases_filter[0] == this.allDiseases) {
        selected_disease_names = this.map_diseases.map((disease) => { if (disease.name != this.allDiseases) return disease.name });
      } else {
        selected_disease_names = [...this.selected_diseases_filter]
      }

      for (let i = 0; i < clusterMarkers.length; i++) {
        let marker_diseases = clusterMarkers[i].options.data_object.diseases;
        let marker_syndromes = clusterMarkers[i].options.data_object.syndromes;
        populate_donut_counts(marker_diseases, selected_disease_names);
        populate_donut_counts(marker_syndromes, this.selected_syndromes_filter);
        // cluster_report_count += clusterMarkers[i].options.data_object.report_count;
      }

      let offsets = [];
      let total = 0;
      let sorted_counts_keys = Object.keys(counts).sort();
      sorted_counts_keys.map((key) => {
        offsets.push(total);
        total += counts[key];
      });
      var radius =
        total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      var width = radius * 2 * this.svg_hover_scale;
      var height = width;

      return new L.point(width, height);
    },
    cluster_anim_end() {
      if (this.map_state == "normal") {  
        this.add_svg_listeners();
        this.clear_country_outlines();
        this.clear_piechart_tooltip();
      }
    },
    clear_country_outlines() {
      for (const key in this.countryOutlineByCountry) {
        this.countryOutlineByCountry[key].remove();
      }
    },
    clear_piechart_tooltip() {
      this.$refs.pie_chart_tooltip.setAttribute("style", "display:none");
    },
    add_svg_listeners() {
      // console.log("add svg listeners");
      var svgPathElements = document.getElementsByClassName(
        "cluster-mouse-events"
      );
      console.log("add svg listeners", svgPathElements.length);
      var eventEnter = (event, element, touch) => {
        touchElement = touch ? document.elementFromPoint(event.touches[0].pageX,event.touches[0].pageY) : null;
          element.setAttribute(
            "transform",
            "scale(" + this.svg_hover_scale + ")"
          );
          var posTop, posLeft;
          if (touch) {
            posTop = event.touches[0].clientY - 120 + "px"; // always 10px below the cursor
            posLeft = event.touches[0].clientX + 10 + "px"; // always 10px below the cursor
          } else {
            posTop = this.mobile ? event.clientY - 50 + "px" : event.clientY - 50 + "px"; // always 10px below the cursor
            posLeft = this.mobile ? event.clientX + "px" : event.clientX - 240 + "px"; // always 10px below the cursor
          }
          element.setAttribute("stroke", "white");
          element.setAttribute("stroke-width", "2px");
          this.$refs.pie_chart_tooltip.setAttribute("style",
            "display:block; top:" + posTop + "; left:" + posLeft);
          this.$refs.pie_chart_tooltip.querySelector(".label").innerHTML =
            "<b>" + element.getAttribute("data-label") + "</b>";
          if (element.getAttribute("data-count") === "1")
          {  
            this.$refs.pie_chart_tooltip.querySelector(".count").innerHTML =
              "<i>" + element.getAttribute("data-count") + ` ${this.$t('map.report_tag')}</i>`;
          } else {
            this.$refs.pie_chart_tooltip.querySelector(".count").innerHTML =
              "<i>" + element.getAttribute("data-count") + ` ${this.$t('map.reports_tag')}</i>`;
          }
      }
      var eventExit = (event, element) => {
        element.setAttribute("transform", "scale(1)");
        element.setAttribute("stroke", "none");

        this.$refs.pie_chart_tooltip.setAttribute("style", "display:none");
      }
      // console.log(svgPathElements)
      for (var i = 0; i < svgPathElements.length; i++) {
        var touchElement = null
        svgPathElements[i].addEventListener('mouseover', (event) => {
          if (!this.activeTouchEvent) {
            // console.log("mouseover")
            eventEnter(event, event.target, false)
          }
        });
        svgPathElements[i].addEventListener('mouseout', (event) => {
          if (!this.activeTouchEvent) {
            // console.log("mouseout")
            eventExit(event, event.target)
          }
        });
        svgPathElements[i].addEventListener('mousemove', (event) => {
          if (!this.activeTouchEvent) {
            eventEnter(event, event.target, false)
          }
        });
        svgPathElements[i].addEventListener('touchstart', (event) => {
          this.activeTouchEvent = true
          // console.log("touchstart")
          eventEnter(event, event.target, true)
          this.$refs.mainmap.mapObject.dragging.disable()
        });
        svgPathElements[i].addEventListener('touchend', (event) => {
          this.activeTouchEvent = false
          // console.log("touchend")
          eventExit(event, touchElement)
          this.$refs.mainmap.mapObject.dragging.enable()
        });
        svgPathElements[i].addEventListener('touchcancel', (event) => {
          this.activeTouchEvent = false
          // console.log("touchcancel")
          eventExit(event, touchElement)
          this.$refs.mainmap.mapObject.dragging.enable()
        });
        svgPathElements[i].addEventListener('touchmove', (event) => {
          // console.log("touchmove")
          this.activeTouchEvent = true
          var element = touchElement
          element = !element ? event.target : element
          if (element !== document.elementFromPoint(event.touches[0].pageX, event.touches[0].pageY)) {
              eventExit(event, element)
              var currTouchElement = document.elementFromPoint(event.touches[0].pageX, event.touches[0].pageY)
              if (currTouchElement.classList.contains("cluster-mouse-events")) {
                touchElement = currTouchElement
              }
          } else {
            eventEnter(event, element, true)
          }
          this.$refs.mainmap.mapObject.dragging.disable()
        });
      }

      var svgCircleElements = document.getElementsByClassName(
        "cluster-centre-circle"
      );
      for (i = 0; i < svgCircleElements.length; i++) {
        // console.log(svgCircleElements[i]);
        svgCircleElements[i].addEventListener("mouseover", (event) => {
          this.test_hover(event);
        });
      }
    },
    test_hover(event) {
      /* eslint-disable-line no-unused-vars */
      //console.log(event);
      // var tooltip = L.tooltip({
      //         direction: 'center',
      //         permanent: true,
      //         interactive: true,
      //         noWrap: true,
      //         opacity: 0.9
      //     });
      // tooltip.setContent( "Example" );
      // tooltip.setLatLng(new L.LatLng(0, 0));
      // tooltip.addTo(this.$refs.mainmap);
    },
    marker_mouseover(event) {
      if (
        event.target.options.data_object.iso3 in this.countryOutlineByCountry
      ) {
        this.countryOutlineByCountry[
          event.target.options.data_object.iso3
        ].addTo(this.$refs.mainmap.mapObject);
      } else {
        // console.log("Not found " + event.target.options.data_object.iso3);
      }
    },
    marker_mouseout() {
      this.clear_country_outlines();
    },
    marker_doubleclick(event) {
      // console.log("double click on", event);
      // console.log(event.target.options.data_object.country_name);
      // remove any previous sub country markers
      this.sub_country_cluster.clearLayers();
      // this.sub_country_markers.forEach((marker) => {
      //     this.$refs.mainmap.mapObject.removeLayer(marker);
      // })
      // remove all the country markers
      for (const region in this.regionalMarkerClusters) {
        this.regionalMarkerClusters[region].clearLayers();
      }
      this.sub_country_markers = [];
      var greenIcon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      // go through each report of the marker that was double clicked and add a green marker
      event.target.options.data_object.reports.forEach((report) => {
        // if lat/lon not present on the report, use the lat/long of the country instead
        var lat, long;
        if ((report.report_location.lat == null) || (report.report_location.long == null)) {
          lat = event.target.options.data_object.lat;
          long = event.target.options.data_object.long;
        } else {
          lat = report.report_location.lat;
          long = report.report_location.long;
        }
        var marker = L.marker(new L.LatLng(lat, long), {
          icon: greenIcon,
          data_object: report,
        });

        this.sub_country_markers.push(marker);
        marker.addTo(this.sub_country_cluster);
        var html =
          "<div>" +
          report["publication-date"] +
          " -- <a href='" +
          report["url"] +
          "' target='_blank'>" +
          report["title"] +
          "</a></div>";
        marker.bindPopup(html);
        // marker.bindTooltip(this.get_tooltip_html(country));
      });

      //show the statusbar
      this.$refs.status_bar.innerHTML =
        "Showing reports for " +
        event.target.options.data_object.country_name +
        ". Click here or press ESC to return to main map.";
      this.$refs.status_bar.setAttribute("style", "display:block;");
    //   console.log(this.$refs.mainmap);
      this.$refs.mainmap.$el.focus();

      this.map_state = "sub_country";
    },
    cluster_mouseover(event) {
      // console.log("cluster mouseover", event);
      event.layer.getAllChildMarkers().forEach((child) => {
        if (child.options.data_object.iso3 in this.countryOutlineByCountry) {
          this.countryOutlineByCountry[child.options.data_object.iso3].addTo(
            this.$refs.mainmap.mapObject
          );
        }
      });
    },
    cluster_mouseout() {
      // console.log("cluster mouseout");
      this.clear_country_outlines();
    },
    create_markers_clusters() {
      console.log("create clusters");
      // console.log(JSON.parse(JSON.stringify(this.map_data)));
      // console.log(this);

      // add each country marker to its correct regional cluster
      this.map_data.forEach((country) => {
        // console.log(country.iso3);
        if (!country.iso3) return null;
        var lat, lon;
        if (country.iso3 in this.geojsonByCountry) {
          // console.log(this.geojsonByCountry, country.iso3);
          // console.log(this.geojsonByCountry[country.iso3]["geometry"]["coordinates"]);
          // Find the "pole of inaccessibility" for each country, as per https://github.com/mapbox/polylabel
          var pos;
          if (
            this.geojsonByCountry[country.iso3]["geometry"]["type"] ===
            "Polygon"
          ) {
            // If the country id defined by a single polygon, find the "centre" of that polygon
            pos = polylabel(
              this.geojsonByCountry[country.iso3]["geometry"]["coordinates"]
            );
          } else if (
            this.geojsonByCountry[country.iso3]["geometry"]["type"] ===
            "MultiPolygon"
          ) {
            // Otherwise if the country is defined my a multipolygon, find the centre of each of those polygons,
            // and then use the one that has the largest "distance"
            var max_dist = 0;
            // console.log("MULTI");
            for (
              var i = 0;
              i <
              this.geojsonByCountry[country.iso3]["geometry"]["coordinates"]
                .length;
              i++
            ) {
              var test_polylabel = polylabel(
                this.geojsonByCountry[country.iso3]["geometry"]["coordinates"][
                  i
                ]
              );
              if (test_polylabel["distance"] > max_dist) {
                pos = test_polylabel;
                max_dist = pos["distance"];
              }
            }
          }

          // var poly2 = [[[0,0], [0, 100], [100, 100], [100, 0]]]
          // console.log(this.geojsonByCountry[country.iso3]["geometry"]["type"])
          // console.log(poly);
          // console.log(poly2);
          // var pos = polylabel(poly, 1.0);
          // // var pos = polylabel([[[0,0], [0, 100], [100, 100], [100, 0]]], 1.0);
          // // var pos = polylabel(this.geojsonByCountry[country.iso3]["geometry"]["coordinates"], 1.0);
          // console.log("**", pos);
          lat = pos[1];
          lon = pos[0];
        } else {
          lat = country.lat;
          lon = country.long;
        }

        var marker = L.marker(new L.LatLng(lat, lon), {
          data_object: country,
        });
        var region = this.find_region(country.iso3);
        // console.log(regionMarkerGroups);
        if (region) this.regionalMarkerClusters[region].addLayer(marker);

        // adding to group, not directly to map any more
        // marker.addTo(this.$refs.mainmap.mapObject);
        marker.on("mouseover", this.marker_mouseover);
        marker.on("mouseout", this.marker_mouseout);
        // marker.on("dblclick", this.marker_doubleclick);
        // marker.bindPopup(this.get_popup_html(country));
        marker.bindTooltip(this.get_tooltip_html(country));
      });

      // then add the regional clusters to the map
      for (const key in this.regionalMarkerClusters) {
        this.regionalMarkerClusters[key].addTo(this.$refs.mainmap.mapObject);
        this.regionalMarkerClusters[key].on(
          "animationend",
          this.cluster_anim_end
        );
      }
      this.sub_country_cluster.addTo(this.$refs.mainmap.mapObject);

      this.add_svg_listeners();
    },
    do_api_update() {
      //   console.log("do api update");
      //   console.trace();
      if (this.finished_data_load) {
        this.finished_data_load = false;

        if (this.map_state == "sub_country") {
          this.close_sub_country_markers();
        }
        
        var data_promise;
        data_promise = this.get_data_from_api();
        data_promise.then((data) => {
          this.map_data = data;
          // this.map_data = [];
          for (const region in this.regions) {
            // console.log("Adding cluster group for", key);

            //   var cluster = L.markerClusterGroup({
            //       spiderfyOnMaxZoom: false,
            //       showCoverageOnHover: false,
            //       zoomToBoundsOnClick: false
            //   });
            this.regionalMarkerClusters[region].clearLayers();
          }
          this.create_markers_clusters();
          this.finished_data_load = true;
        });
      }
    },
    apply_disease_syndrome_filter() {
      // console.log("apply_disease_syndrome_filter");
      this.selected_diseases_filter = [...this.selected_diseases];
      this.selected_syndromes_filter = [...this.selected_syndromes];

      let selected_disease_names = []
      if (this.selected_diseases_filter[0] == this.allDiseases) {
        selected_disease_names = this.map_diseases.map((disease) => { if (disease.name != this.allDiseases) return disease.name });
      } else {
        selected_disease_names = [...this.selected_diseases_filter]
      }

      this.selected_diseases_syndromes_filter = [
        ...selected_disease_names,
        ...this.selected_syndromes_filter,
      ].sort();
      this.do_api_update();
      this.disease_syndrome_menu = false;
      this.get_cluster_colors();

    },
    clear_disease_syndrome_filter() {
      // console.log("clear_disease_syndrome_filter");
      this.selected_diseases_filter = [];
      this.selected_syndromes_filter = [];
      this.selected_diseases = [];
      this.selected_syndromes = [];
      this.selected_diseases_syndromes_filter = [];
      this.do_api_update();
      this.disease_syndrome_menu = false;
      this.get_cluster_colors();

    },
    apply_all_disease_filter() {
      //   console.log("apply_all_disease_filter");
      // let disease_names = this.get_diseases.map((disease) => disease.name);
      // this.selected_diseases = [...disease_names];
      this.selected_diseases = [this.allDiseases];
    },
    unselect_disease(item) {
      const index = this.selected_diseases.indexOf(item.name);
      if (index >= 0) this.selected_diseases.splice(index, 1);
    },
    unselect_syndrome(item) {
      const index = this.selected_syndromes.indexOf(item.name);
      if (index >= 0) this.selected_syndromes.splice(index, 1);
    },
    get_popup_html(country) {
      let html = "<div style='max-height: 200px; overflow-y: auto'>";
      html += "<span>";

      const report_mentions_html = (country_disease_syndrome, str_type) => {
        if (Object.keys(country_disease_syndrome).length > 0) {
          html += "<b>" + str_type + "</b>: ";
          let sorted_keys = Object.keys(country_disease_syndrome).sort();
          for (let i = 0; i < sorted_keys.length; i++) {
            let this_key = sorted_keys[i];
            if (this.selected_diseases_syndromes_filter.includes(this_key.toLowerCase())) {
              html +=
                this_key.toLowerCase() + " (" + country_disease_syndrome[this_key] + ")";
            } else {
              html +=
                "<i>" +
                this_key.toLowerCase() +
                " (" +
                country_disease_syndrome[this_key] +
                ")</i>";
            }
            if (i + 1 < Object.keys(country_disease_syndrome).length) {
              html += ", ";
            }
          }
        }
      };

      report_mentions_html(country.diseases, "Diseases");
      if (
        Object.keys(country.diseases).length > 0 &&
        Object.keys(country.syndromes).length > 0
      ) {
        html = html + "<br />";
      }
      report_mentions_html(country.syndromes, "Syndromes");

      let reports = [...country.reports];
      reports.sort((r1, r2) => {
        return (
          new Date(r2["publication-date"]) - new Date(r1["publication-date"])
        );
      });
      html += "</span><hr>";

      for (let i = 0; i < reports.length; i++) {
        html +=
          "<div>" +
          reports[i]["publication-date"] +
          " -- " +
          "<a href='" +
          reports[i]["url"] +
          "' target='_blank'>" +
          reports[i]["title"] +
          "</a></div>";
      }
      html += "</div>";

      return "<span>" + html + "</span>";
    },
    get_tooltip_html(country) {
      let final_string =
        "<b>" + country.country_name + " (" + country.iso3 + ")" + "</b><br />";
      final_string += "<i>" + country.report_count;
      if (country.report_count === 1)
        final_string += ` ${this.$t('map.report_tag')}</i><br />`;
      else  
        final_string += ` ${this.$t('map.reports_tag')}</i><br />`;
      // final_string += country.diseases.join('<br/>')
      return final_string;
    },
    map_loaded() {
    //   this.$refs.mainmap.mapObject.getRenderer(
    //     this.$refs.mainmap.mapObject
    //   ).options.padding = 1.0;
      this.$refs.mainmap.mapObject.on("keydown", this.keydown);
    },
    legend_mouseover(event) {
      // console.log(event);
      // console.log(event.target.textContent);
      // make legend text bold
      event.target.style = "font-weight:bold";

      // highlight all the SVG path elements for the disease
      var svgPathElements = document.getElementsByClassName(
        "cluster-mouse-events"
      );
      // console.log(svgPathElements);
      for (var i = 0; i < svgPathElements.length; i++) {
        if (
          svgPathElements[i].getAttribute("data-label") ===
          event.target.textContent
        ) {
          svgPathElements[i].setAttribute("stroke", "white");
          svgPathElements[i].setAttribute(
            "transform",
            "scale(" + this.svg_hover_scale + ")"
          );
        }
      }

      // highight all the pins with the disease
      this.highlight_pins_with_report(event.target.textContent);
    },
    legend_mouseout(event) {
      event.target.style = "";
      var svgPathElements = document.getElementsByClassName(
        "cluster-mouse-events"
      );
      // console.log(svgPathElements);
      for (var i = 0; i < svgPathElements.length; i++) {
        if (
          svgPathElements[i].getAttribute("data-label") ===
          event.target.textContent
        ) {
          svgPathElements[i].setAttribute("stroke", "none");
          svgPathElements[i].setAttribute("transform", "scale(1)");
        }
      }
      this.unhighlight_pins();
    },
    get_illness_ids() {
      let disease_ids = []
      let syndrome_ids = []

      if (this.selected_diseases_filter.length > 0) {
        if (this.selected_diseases[0] == this.allDiseases) {
          disease_ids = ['ALL DISEASES']
        } else {
          this.map_diseases.map((e) => {
            if (this.selected_diseases.includes(e.name)) {
              disease_ids.push(e.id)
            }
          })
        }
      }

      if (this.selected_syndromes_filter.length > 0) {
        this.map_syndromes.map((e) => {
          if (this.selected_syndromes.includes(e.name)) {
            syndrome_ids.push(e.id)
          }
        })
      }

      return { disease_ids, syndrome_ids }

    },
    get_data_from_api() {
      let bodyObjectToSend = {};
      // console.log("SELECTED DISEASES")
      // console.log(this.selected_diseases)
      // disease_names = [...this.selected_diseases]
      let { disease_ids, syndrome_ids } = this.get_illness_ids();
      // console.log(disease_names)
      bodyObjectToSend.filters = {
        start_date: this.date_range_start,
        end_date: this.date_range_end,
        disease_ids: disease_ids,
        syndrome_ids: syndrome_ids,
      };
      // console.log("Getting map data from api");

      // blocks from posting, when user hasn't given either a disease or syndrome
      // want to avoid populating map with all the reports, due to donut chart marker cluster
      if (
        this.selected_diseases_filter.length == 0 &&
        this.selected_syndromes_filter.length == 0
      ) {
        return new Promise((resolve /*, reject*/) => {
          resolve([]);
        });
      }

      return new Promise((resolve, reject) => {
        axios
          .post(config.api_url + "api/map", bodyObjectToSend, this.headerOptions)
          .then((response) => {
            console.log("HERE", response);
            this.finished_data_load = false;
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    get_data_from_local() {
      //   console.log("Getting map data from local");
      this.finished_data_load = false;

      return fetch("data/map.json").then((response) => response.json());
    },
    fetch_diseases() {
      return new Promise((resolve, reject) => {
        axios
          .get(config.api_url + "api/disease", this.headerOptions)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    fetch_syndromes() {
      return new Promise((resolve, reject) => {
        axios
          .get(config.api_url + "api/syndrome", this.headerOptions)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    fetch_world_geojson() {
      return new Promise((resolve, reject) => {
        axios
          .get("data/world-low.json")
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    add_country_geojson(feature) {
      var continentName = feature.properties.continent;
      var countryName = feature.properties.name;
      var isoName = feature.properties.iso_a3;
      // console.log("Adding " + countryName + " to " + continentName);
      this.continents[continentName]["countries"].push(countryName);
      this.geojsonByCountry[isoName] = feature;
      this.countryOutlineByCountry[isoName] = L.geoJSON(feature, {
        style: { color: "#ffff00" },
      });
      //this.countryOutlineByCountry[countryName].addTo(this.$refs.mainmap.mapObject);
    },
    // Searches through the regions and returns which region the country is in.
    // eg : find_region("AUS") -> "Oceania"
    // return false if country is not found in any region
    find_region(iso_a3) {
      // console.log("find " + iso_a3);
      for (const [key, value] of Object.entries(this.regions)) {
        // console.log(value);
        if (value.includes(iso_a3)) {
          // console.log(iso_a3 + " is in " + key);
          return key;
        }
      }
      return false;
    },
    fetch_regions() {
      return new Promise((resolve, reject) => {
        axios
          .get("data/regions.json")
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {});
      });
    },
    highlight_pins_with_report(disease) {
      var goldIcon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      for (var i in this.regionalMarkerClusters) {
        // console.log(this.regionalMarkerClusters[i]);
        const regionMarkers = this.regionalMarkerClusters[i].getLayers();
        for (var j = 0; j < regionMarkers.length; j++)
        {
          if (regionMarkers[j] === this.regionalMarkerClusters[i].getVisibleParent(regionMarkers[j]))
          {
            // console.log(regionMarkers[j].options.data_object.country_name, "is a pin");
            // console.log(JSON.stringify(regionMarkers[j].options.data_object));
            let diseases_and_syndromes = []
            Object.keys(regionMarkers[j].options.data_object.diseases).map((el) => diseases_and_syndromes.push(el.toLowerCase()))
            Object.keys(regionMarkers[j].options.data_object.syndromes).map((el) => diseases_and_syndromes.push(el.toLowerCase()))
            if (diseases_and_syndromes.indexOf(disease) >= 0) {
              // console.log("Highlight");
              regionMarkers[j].setIcon(goldIcon);
            }
          }
        }
      }
    },
    unhighlight_pins() {
      var blueIcon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      for (var i in this.regionalMarkerClusters) {
        const regionMarkers = this.regionalMarkerClusters[i].getLayers();
        for (var j = 0; j < regionMarkers.length; j++)
        {
          regionMarkers[j].setIcon(blueIcon);
        }
      }
    },
    keydown(e) {
      if (e.originalEvent.code === "Escape") {
        // console.log("ESC pressed!");
        this.close_sub_country_markers();
      } 
    },
    status_bar_click() {
      // console.log("click");
      this.close_sub_country_markers();
    },
    close_sub_country_markers() {
      // remove all sub country markers
      this.sub_country_cluster.clearLayers();
      // remove all the country markers
      for (const region in this.regionalMarkerClusters) {
        this.regionalMarkerClusters[region].clearLayers();
      }

      // add the country markers back in
      this.create_markers_clusters();
      this.sub_country_state = false;
      // remove any country outlines
      this.clear_country_outlines();
      this.$refs.status_bar.setAttribute("style", "display:none;");
      if (this.$refs.mainmap.mapObject.getZoom() > 5) {
        this.$refs.mainmap.mapObject.setZoom(5);
      }
      this.map_state = "normal";
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  },
  async mounted() {
    let lang = ['en', 'hi'].includes(this.$route.query.lang) ? this.$route.query.lang : 'en';
    if (this.$i18next.language !== lang) {
      i18next.changeLanguage(lang);
    }
    this.headerOptions = {
                    headers: {
                        "Accept-Language": lang
                    }
                };
    this.allDiseases = this.$t("map.all_diseases_upper");
    this.touchDevice = 'ontouchstart' in document.documentElement;
    this.d3_colour_scale = d3.scaleOrdinal(d3.schemeCategory10);
    // apply default filter and render map
    this.fetch_regions().then((data) => {
        // console.log("Got regions data", data);
      this.regions = data;
      for (const key in this.regions) {
        // console.log("Adding cluster group for", key);

        //   var cluster = L.markerClusterGroup({
        //       spiderfyOnMaxZoom: false,
        //       showCoverageOnHover: false,
        //       zoomToBoundsOnClick: false
        //   });
        var cluster = L.markerClusterGroup(this.clusterOptions2);
        cluster.on("clustermouseover", this.cluster_mouseover);
        cluster.on("clustermouseout", this.cluster_mouseout);
        this.regionalMarkerClusters[key] = cluster;
      }
      //   this.find_region("AUS");
      return this.fetch_world_geojson();
    })
    .then((data) => {
        // console.log("Got geojson data", data);
      for (var i = 0; i < data["features"].length; i++) {
        // console.log(jsonData["features"][i]);
        this.add_country_geojson(data["features"][i]);
      }

      return this.fetch_diseases();
    })
    .then((data) => {
        // console.log("got diseases");
        // console.log(data);
        let new_diseases = data.map((el) => {
          let new_disease_name = el["disease"].toLowerCase();
          let new_disease_obj = { ...el, name: new_disease_name, disease: new_disease_name }
          return new_disease_obj;
        });
        this.get_diseases = new_diseases;
        return this.fetch_syndromes();
    })
    .then((data) => {
      let new_syndromes = data.map((el) => {
        let new_syndrome_name = el["syndrome"].toLowerCase();
        let new_syndrome_obj = { ...el, name: new_syndrome_name, syndrome: new_syndrome_name }
        return new_syndrome_obj;
      });
      this.get_syndromes = new_syndromes;
      // console.log("got syndromes");
    });
  },
  created() {
    // console.log(this.$route.query.lang);
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  destroy() {
    window.removeEventListener('resize', this.checkScreen)
  }
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.cluster-div {
  background-clip: padding-box;
  border-radius: 30px;
  margin: 10px;
  text-align: center;
  color: white;
  line-height: 30px;
  background-color: rgba(139, 218, 94, 0.6);
}

.cluster-div div {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 20px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: rgba(37, 114, 7, 0.6);
}

.cluster-div span {
  line-height: 40px;
}

/* leave as empty for donut chart marker cluster*/
.cluster-div2 {
}

.info-tooltip {
  background-color: rgb(226, 237, 240) !important;
  border-color: rgb(72, 110, 180) !important;
  text-align: center;
  pointer-events: auto !important;
}

.info-tooltip:before {
  border-bottom-color: rgb(142, 186, 197) !important;
}

.legend {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0px 0px 3px 3px;
  padding: 10px;
  width: 240px;
  max-height: 210px;
  overflow-y: auto;
  user-select: none;
  margin: -10px -24px -15px -24px;
}

.legend-title {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px 3px 0px 0px;
  padding: 10px;
  width: 240px;
  user-select: none;
}

.legend-title.collapsed {
  border-radius: 3px;
}

.legend-disease-syndrome {
  height: 10px;
  width: 10px;
  margin-right: 2px;
  border-color: 3px solid black;
  display: inline-block;
}

.loading-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.loading-logo-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.loading-logo-animate {
  height: 50px;
  width: 100px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: blinking;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}

.map-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.date-input {
  width: 120px;
}

.map-wrapper {
  position: relative;
  height: 100%;
}

.leaflet-map {
  z-index: 0;
  position: absolute;
  height: 100%;
}

.pie-chart-tooltip {
  display: none;
}

.status-bar {
  background: #eee;
  box-shadow: 0 0 5px #999999;
  color: #333;
  display: none;
  position: relative;
  left: -50%;
  padding: 5px 20px;
  z-index: 401;
  user-select: none;
}

.status-bar-container {
  position: absolute;
  left: 50%;
  bottom: 30px;
  z-index: 401;
}

</style>