<template>
    <v-container max class="fill-height pa-0" fluid>
        <v-row id="map" style="height:100%" class="pr-0 mx-0">
            <v-col class="pt-0 pr-0 pb-0 pl-0">
                <reports-map></reports-map>
                <dashboard-navigation/>
            </v-col>
        </v-row>

  </v-container>

</template>
<script>

// import { mapGetters } from 'vuex'
// import { mapActions } from 'vuex'

import ReportsMap from '@/components/dashboard/ReportsMap.vue'
import DashboardNavigation from '@/components/dashboard/DashboardNavigation.vue'

  export default {
    components: {
      ReportsMap,
      DashboardNavigation
    },
    computed: {
      get_route_name() {
        try {
          return this.$route.name
        } catch(e) {
          return ""
        }
      }
    },
    data() {
      return {
        navItems: [
          { itemName: "HOME", routeName: "Home", linkTo: "/", itemIcon: 'mdi-home'},
          { itemName: "MAP", routeName: "Map", linkTo: "/map", itemIcon: "mdi-map-marker"},
          { itemName: "STATS", routeName: "Stats", linkTo: "/stats", itemIcon: "mdi-signal"},
          { itemName: "REPORTS", routeName: "Reports Table", linkTo: "/reports", itemIcon: "mdi-table"}
        ]
      }
    },
    methods: {

    },
    mounted() {

    },
    created() {

    }
  };
</script>
<style lang="scss">
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
