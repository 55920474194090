<template>
  <div>
  <app-navigation />
  <div class="white-section" style="margin-top: 60px; padding-top: 70px; height: calc(100vh - 148px) !important;">
    <div class="section-title">EPISCOPE</div>
    <div style="flex: 1; align-items: center; text-align: left;" :class="mobile ? 'section-paragraph-mobile' : 'section-paragraph;'">
We are still tracking epidemics continually and you can keep up with epidemic intelligence by contacting us for customised or general summaries. If you would like to subscribe to EPISCOPE, please contact us <a href="/contact">here</a>.</div>
    <p></p>
    <v-btn x-large class="red-button" to="/">Return to home</v-btn>
  </div>
  <app-footer />
  </div>
</template>

<script>
import AppNavigation from '@/components/AppNavigation.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'DashboardPlaceholder',
  components: {
      AppNavigation,
      AppFooter
  },
    data: () => ({
    mobile: null,
    windowWidth: null
  }),
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    this.checkScreen()
  },
  destroy() {
    window.removeEventListener("resize", this.checkScreen);
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
      return;
    }
  },
};
</script>